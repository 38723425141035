<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <v-flex xs3>
        <v-text-field
          v-model="addressRef['unit']"
          name="patientName"
          label="Unit #"
          type="text"
        >
        </v-text-field>
      </v-flex>
      <v-flex xs3>
        <v-text-field
          v-model="addressRef['streetNumber']"
          name="patientName"
          label="Street number"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>
      <v-flex xs6>
        <v-text-field
          v-model="addressRef['streetName']"
          name="patientName"
          label="Street name"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex xs3>
        <v-text-field
          v-model="addressRef['city']"
          name="patientName"
          label="City"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex xs3>
        <v-text-field
          v-model="addressRef['province']"
          name="patientName"
          label="Province"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>

        <!-- just a quick fix for provinces, prepopulating the patients province instead of having it in a dropdown  -->
        <!-- <v-select
                    autocomplete
                    auto
                    :options="provinces"
                    v-model="selected"
                    label="Province"
                ></v-select> -->
      </v-flex>

      <v-flex xs3>
        <v-text-field
          v-model="addressRef['postalCode']"
          name="patientName"
          label="Postal Code"
          :rules="[rules.required]"
          type="text"
          mask="A#A #A#"
        >
        </v-text-field>
      </v-flex>

      <v-flex xs3>
        <v-text-field
          v-model="addressRef['pobox']"
          name="patientName"
          label="PO BOX"
          type="text"
        >
        </v-text-field>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
// import { rules } from "../../util/validations.js";
import { rules } from '@/util/index'

export default {
  name: 'AddressForm',
  props: ['address'],

  //   created() {
  //     if (this.addressRef['Province']) {
  //       this.addressRef['Province'] = this.addressRef['Province'];
  //     }
  //   },
  data() {
    return {
      addressRef: this.address,
      rules: {
        ...rules,
      },

      //   selected: [
      //       "ONTARIO"
      //   ],
      //   provinces: [
      //     "ALBERTA",
      //     "BRITISH COLUMBIA",
      //     "MANITOBA",
      //     "NEW BRUNSWICK",
      //     "NEWFOUNDLAND AND LABRADOR",
      //     "NORTHWEST TERRITORIES",
      //     "NOVA SCOTIA",
      //     "NUNAVUT",
      //     "ONTARIO",
      //     "PRINCE EDWARD ISLAND",
      //     "QUEBEC",
      //     "SASKATCHEWAN",
      //     "YUKON TERRITORy"
      //   ]
    }
  },
}
</script>

<style scoped>
</style>
