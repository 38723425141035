<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        id="v-step-2"
        xs6
      >
        <v-select
          v-model="personalInfo['MEDSCHECK SERVICE PROVIDED']"
          :items="MEDSCHECK_LIST"
          label="What type of medscheck?"
          single-line
          autocomplete
          @change="dropdownChangeMedscheckService"
        ></v-select>
      </v-flex>

      <v-flex
        id="v-step-3"
        xs6
      >
        <v-select
          v-model="personalInfo['LOCATION']"
          :items="LOCATION_LIST"
          label="Where was the medscheck conducted?"
          single-line
          autocomplete
          @change="dropdownChangeConducted"
        ></v-select>
      </v-flex>

      <!-- <v-flex xs12>
                <radio-list :state="personalInfo" :list="MEDCHECK_PLACE_LIST"></radio-list>
            </v-flex> -->

      <v-flex xs12>
        <v-text-field
          v-model="personalInfo['PATIENT ACKNOWLEDGEMENT COMMENTS']"
          label="Patient medscheck acknowledgement comments"
          :rules="[rules.required]"
          multi-line
          rows="3"
        >
        </v-text-field>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/index'
import Checklist from '@/components/common/Checklist/Checklist.vue'
import Radiolist from '@/components/common/Radiolist/Radiolist.vue'

// const MEDCHECK_PLACE_LIST = [
//     {
//         name: "MEDSCHECK AT PHARMACY",
//         label: "Medscheck completed in pharmacy",
//         value: "Yes"
//     },
//     {
//         name: "MEDSCHECK AT PATIENT HOME",
//         label: "Medscheck completed at patients home",
//         value: "Yes"
//     }
// ]

const CHECKLIST_COLLECTION = [
  {
    name: 'MEDSCHECK ANNUAL',
    label: 'This is an annual medscheck',
    value: 'Yes',
  },
  {
    name: 'MEDSCHECK FOLLOW-UP',
    label: 'This is a follow up medscheck',
    value: 'Yes',
  },
  {
    name: 'MEDSCHECK FOR DIABETES ANNUAL',
    label: 'This is a diabetes annual medscheck',
    value: 'Yes',
  },
  {
    name: 'DIABETES EDUCATION FOLLOW-UP',
    label: 'This is a diabetes follow up medscheck',
    value: 'Yes',
  },
  {
    name: 'MEDSCHECK AT HOME',
    label: 'This is a medscheck at home',
    value: 'Yes',
  },
]

// const CHECKLIST_COLLECTION = [
//     {
//         name: "MEDSCHECK ANNUAL CHECKBOX ACKNOWLEDGMENET",
//         label: "This is an annual medscheck",
//         value: "Yes"

//     },
//     {
//         name: "MEDSCHECK FOR DIABETES CHECKBOX ACKNOWLEDGMENET",
//         label: "This is a diabetes annual medscheck",
//         value: "Yes"
//     },
//     {
//         name: "MEDSCHECK FOLLOW-UP CHECKBOX ACKNOWLEDGMENET",
//         label: "This is a follow up medscheck",
//         value: "Yes"

//     },
//     {
//         name: "DIABETES EDUCATION FOLLOW-UPMEDSCHECK CHECKBOX ACKNOWLEDGMENET",
//         label: "This is a diabetes follow up medscheck",
//         value: "Yes"

//     },
//     {
//         name: "MEDSCHECK AT HOME CHECKBOX ACKNOWLEDGMENET",
//         label: "This is a medscheck at home",
//         value: "Yes"

//     }
// ];

const CHECKLIST_CLINICAL_COLLECTION = [
  {
    name: 'NEW PATIENT TO PHARMACY',
    label: 'Medscheck for new patient',
    value: 'Yes',
  },
  {
    name: 'ANNUAL REVIEW',
    label: 'Medscheck for annual review',
    value: 'Yes',
  },
  {
    name: 'PATIENT REQUEST',
    label: 'Medscheck at patient request',
    value: 'Yes',
  },
  {
    name: 'PHYSICAIN REFERRAL',
    label: 'Medscheck at physician referral',
    value: 'Yes',
  },
  {
    name: 'MEDICATION CHANGES',
    label: 'Medscheck due to medication changes',
    value: 'Yes',
  },
]

const CHECKLIST_CRITIRIA_COLLECTION = [
  {
    name: '3 OR MORE MEDICATIONS',
    label: 'Takes 3 or more meds',
    value: 'Yes',
  },
  {
    name: 'MULTIPLE CONDITIONS',
    label: 'Has multiple conditions',
    value: 'Yes',
  },
  {
    name: 'YesE OR MORE NON RX MEDS',
    label: 'Takes non rx meds',
    value: 'Yes',
  },
  {
    name: 'YesE OR MORE NHP',
    label: 'Takes natural health products',
    value: 'Yes',
  },
  {
    name: 'UNDADDRESSED SYMPTOMS',
    label: 'Has unaddressed symptoms',
    value: 'Yes',
  },
  {
    name: 'POTENTIAL DRPS',
    label: 'Potential drug therapy problems',
    value: 'Yes',
  },
  {
    name: 'MULTIPLE DOCTORS',
    label: 'Has multiple prescribers',
    value: 'Yes',
  },
  {
    name: 'ISSUES EARLY OR LATE REFILLS',
    label: 'Has compliance issues',
    value: 'Yes',
  },
  {
    name: 'NON-ADHERENCE',
    label: 'Is non-adherent with regimen',
    value: 'Yes',
  },
  {
    name: 'PATIENT CONFUSED',
    label: 'Is confused with regimen',
    value: 'Yes',
  },
  {
    name: 'MEDS WITH LAB MONITORING',
    label: 'Requires regular lab monitoring',
    value: 'Yes',
  },
  {
    name: 'ABNORMAL LAB RESULTS',
    label: 'Has abnormal lab results',
    value: 'Yes',
  },
  {
    name: 'PLANNED ADMISSION',
    label: 'Has planned hospital admission',
    value: 'Yes',
  },
  {
    name: 'DISCHARGE FROM HOSPITAL TO COMMUNITY',
    label: 'Recent hospital discharge',
    value: 'Yes',
  },
  {
    name: 'COMPLIANCE PACKAGING',
    label: 'Initiating compliance packaging',
    value: 'Yes',
  },
  {
    name: 'RENAL FUNCTION',
    label: 'Has renal issues',
    value: 'Yes',
  },
  {
    name: 'LIVER FUNCTION',
    label: 'Has hepatic (liver) issues',
    value: 'Yes',
  },
  {
    name: 'PATIENT CHARACTERISTICS OTHER',
    label: 'Has other concerns',
    value: 'Yes',
  },
]

const DROPDOWN_LOCATION_LIST = [
  "Patient's home",
  'Pharmacy',
  'Virtual Medscheck',
]

const DROPDOWN_MEDSCHECK_LIST = [
  'Annual medscheck',
  'Diabetes annual',
  'Diabetes follow up',
  'Follow up',
  'Hospital discharge',
  'Medscheck at home',
  'Physician referral',
]

export default {
  components: {
    // 'check-list': Checklist,
    // 'radio-list': Radiolist
  },
  props: ['patientProfile', 'showABFields'],
  data() {
    return {
      MEDSCHECK_LIST: DROPDOWN_MEDSCHECK_LIST,
      LOCATION_LIST: DROPDOWN_LOCATION_LIST,

      // MEDCHECK_PLACE_LIST,
      checklistCriteria: CHECKLIST_CRITIRIA_COLLECTION,
      checklistClinical: CHECKLIST_CLINICAL_COLLECTION,
      personalInfo: this.patientProfile,
      checklist: CHECKLIST_COLLECTION,
      rules: {
        ...rules,
      },
    }
  },
  methods: {
    // [personalInfo['MEDSCHECK SERVICE PROVIDED']]:
    dropdownChangeMedscheckService(val) {
      this.personalInfo['MEDSCHECK ANNUAL CHECKBOX ACKNOWLEDGMENET'] = ''
      this.personalInfo['MEDSCHECK FOR DIABETES CHECKBOX ACKNOWLEDGMENET'] = ''
      this.personalInfo['MEDSCHECK FOLLOW-UP CHECKBOX ACKNOWLEDGMENET'] = ''
      this.personalInfo[
        'DIABETES EDUCATION FOLLOW-UPMEDSCHECK CHECKBOX ACKNOWLEDGMENET'
      ] = ''
      this.personalInfo['MEDSCHECK AT HOME CHECKBOX ACKNOWLEDGMENET'] = ''
      switch (val) {
        case 'Annual medscheck':
          this.personalInfo['MEDSCHECK ANNUAL CHECKBOX ACKNOWLEDGMENET'] = 'Yes'
          break
        case 'Diabetes annual':
          this.personalInfo['MEDSCHECK FOR DIABETES CHECKBOX ACKNOWLEDGMENET'] =
            'Yes'
          break
        case 'Diabtes follow up':
          this.personalInfo[
            'DIABETES EDUCATION FOLLOW-UPMEDSCHECK CHECKBOX ACKNOWLEDGMENET'
          ] = 'Yes'
          break
        case 'Follow up':
          this.personalInfo['MEDSCHECK FOLLOW-UP CHECKBOX ACKNOWLEDGMENET'] =
            'Yes'
          break
        case 'Hospital discharge':
          this.personalInfo['MEDSCHECK FOLLOW-UP CHECKBOX ACKNOWLEDGMENET'] =
            'Yes'
          break
        case 'Medscheck at home':
          this.personalInfo['MEDSCHECK AT HOME CHECKBOX ACKNOWLEDGMENET'] =
            'Yes'
          break
        case 'Physician referral':
          this.personalInfo['MEDSCHECK FOLLOW-UP CHECKBOX ACKNOWLEDGMENET'] =
            'Yes'
          break
      }
    },
    dropdownChangeConducted(val) {
      this.personalInfo['MEDSCHECK AT PHARMACY'] = ''
      this.personalInfo['MEDSCHECK AT PATIENT HOME'] = ''
      switch (val) {
        case "Patient's home":
          this.personalInfo['MEDSCHECK AT PATIENT HOME'] = 'Yes'
          break
        case 'Pharmacy':
          this.personalInfo['MEDSCHECK AT PHARMACY'] = 'Yes'
          break
        case 'Virtual Medscheck':
          this.personalInfo['MEDSCHECK AT PHARMACY'] = 'Yes'
          break
      }
    },
  },
}
</script>

<style scoped>
</style>
