<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        md6
      >
        <v-textarea
          v-model="personalInfo['THERAPEUTIC ISSUE']"
          label="Therapeutic issue"
          :rules="[rules.required]"
          type="text"
        >
        </v-textarea>
      </v-flex>
      <v-flex
        xs12
        md6
      >
        <v-textarea
          v-model="personalInfo['THERAPEUTIC ISSUE SUGGESTED THERAPY']"
          label="Therapeutic issue suggested therapy"
          :rules="[rules.required]"
          type="text"
        >
        </v-textarea>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/index'

export default {
  props: ['patientProfile'],
  data() {
    return {
      personalInfo: this.patientProfile,
      rules: {
        ...rules,
      },
    }
  },
  updated() {
    this.personalInfo[
      'NOTIFICATION ISSUES'
    ] = `Therapeutic Issue: ${this.personalInfo['THERAPEUTIC ISSUE']} : Suggested Action: ${this.personalInfo['THERAPEUTIC ISSUE SUGGESTED THERAPY']}`
  },
}
</script>

<style scoped></style>
