<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <address-info :address="addressState"></address-info>

      <v-flex
        xs12
        md4
      >
        <v-text-field
          v-model="personalInfo['EMAIL ADDRESS']"
          label="Email"
          :rules="[rules.required]"
          type="email"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md4
      >
        <v-text-field
          v-model="personalInfo['TELEPHONE NUMBER']"
          label="Home Phone"
          :rules="[rules.required]"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md4
      >
        <v-text-field
          v-model="personalInfo['OTHER PHONE NUMBER']"
          label="Alt. Phone"
          type="phone"
          mask="+#(###) ###-####"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['OCCUPATION']"
          label="Occupation"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/index'
import AddressForm from '@/components/common/AddressForm/AddressForm.vue'

export default {
  name: 'PersonalAddress',
  components: {
    'address-info': AddressForm,
  },
  props: ['patientProfile'],
  data() {
    return {
      personalInfo: this.patientProfile,
      addressState: {
        unit: this.patientProfile['UNIT NUMBER'],
        streetNumber: this.patientProfile['STREET NUMBER'],
        streetName: this.patientProfile['STREET NAME'],
        city: this.patientProfile['CITY/TOWN'],
        province: this.patientProfile.PROVINCE,
        postalCode: this.patientProfile['POSTAL CODE'],
        pobox: this.patientProfile['PO BOX'],
      },
      rules: {
        ...rules,
      },
    }
  },
  watch: {
    addressState: {
      handler(state) {
        this.personalInfo = Object.assign(this.personalInfo, {
          'UNIT NUMBER': state.unit,
          'STREET NUMBER': state.streetNumber,
          'STREET NAME': state.streetName,
          'CITY/TOWN': state.city,
          PROVINCE: state.province,
          'POSTAL CODE': state.postalCode,
          'PO BOX': state.pobox,
        })
      },
      deep: true,
    },
  },
  methods: {
    clickInsuranceToggle(checkboxValue) {
      switch (checkboxValue) {
        case 'YES':
          this.personalInfo['PRIVATE INSURANCE YES'] = 'Yes'
          this.personalInfo['PRIVATE INSURANCE NO'] = ''
          break
        case 'NO':
          this.personalInfo['PRIVATE INSURANCE NO'] = 'Yes'
          this.personalInfo['PRIVATE INSURANCE YES'] = ''
          break
        default:
          this.personalInfo['PRIVATE INSURANCE YES'] = ''
          this.personalInfo['PRIVATE INSURANCE NO'] = ''
          break
      }

      return true
    },
    phoneNumFormat() {
      const number = this.personalInfo['TELEPHONE NUMBER']
      const cleaned = `${number}`.replace(/\D/g, '')
      const numberChar = cleaned.length

      if (numberChar === 11) {
        var match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/)
        if (match) {
          const intlCode = match[1] ? '+1 ' : ''
          const finishedNum = [
            intlCode,
            '(',
            match[2],
            ') ',
            match[3],
            '-',
            match[4],
          ].join('')

          return (this.personalInfo['TELEPHONE NUMBER'] = finishedNum)
        }

        return null
      }
      if (numberChar === 10) {
        var match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/)
        if (match) {
          const finishedNum = `(${match[1]}) ${match[2]}-${match[3]}`

          return (this.personalInfo['TELEPHONE NUMBER'] = finishedNum)
        }

        return null
      }
    },
  },
}
</script>

<style scoped>
</style>
