<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <pharmacist-search :key="refresh"></pharmacist-search>

      <v-flex
        xs12
        md4
      >
        <v-text-field
          v-model="personalInfo['PHARMACIST NAME']"
          label="Pharmacist Name"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md4
      >
        <v-text-field
          v-model="personalInfo['OCP']"
          label="Pharmacist OCP #"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>
      <v-spacer></v-spacer>
      <v-btn
        id="v-step-4"
        color="primary"
        primary
        class="my-3"
        @click.native="openPharmacistOverlay"
      >
        Select a Pharmacist
      </v-btn>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import { rules } from '@/util/index'

// import PharmacistSearch from "@components/Forms/common/ui/Form/PharmacistSearch/PharmacistSearch"
import PharmacistSearch from '@/components/common/ui/Form/PharmacistSearch/PharmacistSearch.vue'

export default {
  name: 'PersonalInfo',
  components: {
    'pharmacist-search': PharmacistSearch,
  },
  props: ['patientProfile'],
  data() {
    return {
      refresh: 0,
      personalInfo: this.patientProfile,
      rules: {
        ...rules,
      },
      refresh: 0,
    }
  },
  computed: {
    ...mapGetters(['UserProfile']),
  },
  beforeMount() {
    this.populatePharmacist()
  },
  methods: {
    openPharmacistOverlay() {
      window.searchPharmacist.open(({ UserName, PharmID }) => {
        this.personalInfo['PHARMACIST NAME'] = UserName
        this.personalInfo.OCP = PharmID

        // FIXME need to rewrite in a simple way
        const { pharmacistFirstName, pharmacistLastName } = UserName.trim()
          .split(' ')
          .reduce((cur, val, index) => {
            if (index == 0) {
              cur.pharmacistFirstName = val
            } else if (index == 1) {
              cur.pharmacistLastName = val
            }

            return cur
          }, {})
        this.personalInfo['MEDSCHECK PHARMACIST FIRST NAME'] =
          pharmacistFirstName
        this.personalInfo['MEDSCHECK PHARMACIST LAST NAME'] = pharmacistLastName
        this.$forceUpdate()
      })
    },
    populatePharmacist() {
      if (this.personalInfo.OCP && this.personalInfo['PHARMACIST NAME']) {
        return true // already loaded from state
      }
      if (this.UserProfile.firstName) {
        this.personalInfo['MEDSCHECK PHARMACIST FIRST NAME'] =
          this.UserProfile.firstName
        this.personalInfo['MEDSCHECK PHARMACIST LAST NAME'] =
          this.UserProfile.lastName
        this.personalInfo[
          'PHARMACIST NAME'
        ] = `${this.UserProfile.firstName} ${this.UserProfile.lastName}`
        this.personalInfo.OCP = this.UserProfile.ocp
      }
    },
  },
}
</script>

<style scoped>
</style>
