<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        md12
      >
        <v-checkbox
          v-model="personalInfo['RESOURCES DIABETES PASSPORT']"
          color="primary"
          label="Resources diabetes passport"
          true-value="Yes"
          false-value=""
          hide-details
        ></v-checkbox>
      </v-flex>

      <v-flex
        v-show="personalInfo['RESOURCES DIABETES PASSPORT']"
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['RESOURCES PASSPORT']"
          label="Resources passport"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md12
      >
        <v-checkbox
          v-model="personalInfo['RESOURCES SICK DAYS']"
          color="primary"
          label="Resources sick days"
          true-value="Yes"
          false-value=""
          hide-details
        ></v-checkbox>
      </v-flex>

      <v-flex
        v-show="personalInfo['RESOURCES SICK DAYS']"
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['RESOURCES SICK DAY']"
          label="Resources sick day"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md12
      >
        <v-checkbox
          v-model="personalInfo['RESOURCES AFTER HOURS SUPPORT']"
          color="primary"
          label="Resources after hours support"
          true-value="Yes"
          false-value=""
          hide-details
        ></v-checkbox>
      </v-flex>

      <v-flex
        v-show="personalInfo['RESOURCES AFTER HOURS SUPPORT']"
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['RESOURCES AFTER HOURS']"
          label="Resources after hours"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md12
      >
        <v-checkbox
          v-model="personalInfo['RESOURCES DIRECTORY OF LOCAL COMMUNITY RESOURCES']"
          color="primary"
          label="Resources directory of local community resources"
          true-value="Yes"
          false-value=""
          hide-details
        ></v-checkbox>
      </v-flex>

      <v-flex
        v-show="personalInfo['RESOURCES DIRECTORY OF LOCAL COMMUNITY RESOURCES']"
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['RESOURCES LOCAL RESOURCES']"
          label="Local community resources"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md12
      >
        <v-checkbox
          v-model="personalInfo['RESOURCES OTHER MATEIRALS']"
          color="primary"
          label="Resources other mateirals"
          true-value="Yes"
          false-value=""
          hide-details
        ></v-checkbox>
      </v-flex>

      <v-flex
        v-show="personalInfo['RESOURCES OTHER MATEIRALS']"
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['RESOURCES OTHER MATERIAL']"
          label="Resources other material"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['RESOURCE INFO TO BE ADDED']"
          label="Resource info to be added"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/index'

export default {
  props: ['patientProfile'],
  data() {
    return {
      personalInfo: this.patientProfile,
      rules: {
        ...rules,
      },
    }
  },
}
</script>

<style scoped>
</style>
