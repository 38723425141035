<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        md12
      >
        <v-combobox
          id="v-step-15"
          v-model="personalInfo['SUMMARY OF DISCUSSION']"
          chips
          multiple
          :items="SUMMARYOFDISCUSSION1"
          label="Summary of discussion"
        ></v-combobox>
      </v-flex>

      <v-flex
        xs12
        md6
      >
        <v-combobox
          v-model="personalInfo['SUMMARY PATIENT GOALS']"
          multiple
          chips
          :items="SUMMARYPATIENTGOALS1"
          label="Summary patient goals"
        ></v-combobox>
      </v-flex>

      <v-flex
        xs12
        md6
      >
        <v-text-field
          v-model="personalInfo['PATIENT GOALS NOTES']"
          label="Patient goals notes"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md6
      >
        <v-combobox
          v-model="personalInfo['WHAT I WILL DO TO GET THERE']"
          multiple
          chips
          :items="WHATIWILLDOTOGETTHERE26"
          label="What I will do to get there"
        ></v-combobox>
      </v-flex>

      <v-flex
        xs12
        md6
      >
        <v-text-field
          v-model="personalInfo['WHAT I WILL DO TO GET THERE NOTES']"
          label="What I will do to get there notes"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md6
      >
        <v-combobox
          v-model="personalInfo['RESOURCES AND CONTACTS PROVIDED']"
          multiple
          chips
          :items="RESOURCESANDCONTACTSPROVIDED"
          label="Resources and contacts provided"
        ></v-combobox>
      </v-flex>

      <v-flex
        xs12
        md6
      >
        <v-text-field
          v-model="personalInfo['LIST OF RESOURCES AND CONTACTS PROVIDED']"
          label="List of resources and contacts provided"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex xs12>
        <v-text-field
          v-model="personalInfo['SUMMARY AND GOALS NOTES']"
          label="Summary and goals notes"
          type="text"
          multi-line
          rows="2"
        >
        </v-text-field>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/validations'

const SUMMARYOFDISCUSSION1 = [
  'Assessed side effects and interactions',
  'Discussed adherence and compliance',
  'Initiate blister packs to improve compliance',
  'Reviewed all medications',
  'Reviewed blister packs to ensure compliance',
  'Updated medication record',
]

const SUMMARYPATIENTGOALS1 = [
  'Improve compliance',
  'Avoid complications & side effects',
  'Improve quality of life',
  'Control glucose levels',
  'Control blood pressure',
  'Control cholesterol levels',
  'Improve fitness',
  'Lose weight',
  'Improve diet',
  'Maintain conditions',
]

const RESOURCESANDCONTACTSPROVIDED = [
  'Pharmacy contact information',
  'Blood pressure leaflet',
  'Diabetes leaflet',
  'Diet flyer',
  'Dietician',
  'Gluose levels log book',
  'Local dentist',
  'Local hospital',
  'Local walk-in clinic',
  'Medication handouts',
  'Smoking cessation hotline',
]

const WHATIWILLDOTOGETTHERE26 = [
  'Adhere to medication regimen',
  'Compliance packaging',
  'Contact Physicians office',
  'Delivery of medications',
  'Reviewed blister packs to ensure compliance',
  'Improve diet',
  'Increase activity',
  'Make referral to md',
  'Monitoring of conditions',
]

export default {
  props: ['patientProfile'],
  data() {
    return {
      personalInfo: this.patientProfile,
      SUMMARYOFDISCUSSION1,
      SUMMARYPATIENTGOALS1,
      RESOURCESANDCONTACTSPROVIDED,
      WHATIWILLDOTOGETTHERE26,

      // rules: {
      //     ...rules
      // }
    }
  },
}
</script>

<style scoped>
</style>
