<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        @click="personalInfo['LIST OF ALLERGIES'] = '';"
      >
        <v-checkbox
          v-model="personalInfo['CHECKBOX NO KNOWN DRUG ALLERGIES']"
          color="primary"
          label="Check if no known drug allergies"
          value="Yes"
          hide-details
        ></v-checkbox>
      </v-flex>
      <v-flex xs12>
        <v-text-field
          v-show="!personalInfo['CHECKBOX NO KNOWN DRUG ALLERGIES']"
          v-model="personalInfo['LIST OF ALLERGIES']"
          label="List of allergies"
          type="text"
        >
        </v-text-field>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/index'

export default {
  name: 'PersonalInfo',
  props: ['patientProfile'],
  data() {
    return {
      personalInfo: this.patientProfile,
      rules: {
        ...rules,
      },
    }
  },
  computed: {},
}
</script>

<style scoped>
</style>
