<template>
  <v-data-table
    v-model="selectedMedication"
    :footer-props="{'items-per-page-options': [10, 20, 30, 40, 50]}"
    disable-items-per-page
    :headers="headers"
    :items="medicationsList"
    :items-per-page="20"
    item-key="RxNumber"
    show-select
    event
    sort-by="RxDate"
    :sort-desc="sortDesc"
    @click:row="rowClick"
  >
    <template
      v-for="header in headers.filter((header) =>
        header.hasOwnProperty('formatter')
      )"
      v-slot:[`item.${header.value}`]="{ header, value }"
    >
      {{ header.formatter(value) }}
    </template>
    <template #[`item.onHold`]="{item}">
      <v-chip
        :hidden="!item.onHold"
        small
        class="v-chip-light-bg"
        :color="statusList[item.onHold] || ''"
        :class="`${statusList[item.onHold]}--text`"
      >
        {{ item.onHold }}
      </v-chip>
    </template>
    <template #[`item.Status`]="{item}">
      <v-chip
        :color="item.Status === 'Complete'
          ? 'success'
          :item.Status === 'I' ? 'error'
            :''"
        class="tw-bg-red-500"
      >
        {{ item.Status === null ? 'N/A' : item.Status }}
      </v-chip>
    </template>
  </v-data-table>
</template>

<script>
import { momentTimeZone } from '@/util/functions/index'

export default {
  name: 'MedscheckMedicationsSelection',
  setup() {
    const statusColor = { Yes: 'warning' }

    return { statusColor }
  },
  props: ['medications', 'selected'],
  data() {
    return {
      dateFormat: 'YYYY-MM-DD',
      medicationsList: this.medications,
      selectedMedication: this.selected || [],
      search: '',
      sortDesc: true,
      rowIndex: [],
      statusList: {
        Yes: 'error',
        Hide: '',
      },
      headers: [
        {
          text: 'Rx Date',
          value: 'RxDate',
          width: '15%',
          formatter: x => (x ? momentTimeZone(x) : null),
        },
        {
          text: 'Medication Name',
          value: 'GenericName',
          width: '25%',
        },
        {
          text: 'Strength',
          value: 'MED STR' || 'RxStrength',
          width: '10%',
        },
        {
          text: 'SIG',
          value: 'SIG' || 'SIGFull',
          width: '40%',
        },
        {
          text: 'On Hold',
          value: 'onHold',
          width: '10%',
        },
        {
          text: 'Status',
          value: 'Status',
          width: '10%',
        },
      ],
    }
  },
  watch: {
    selectedMedication() {
      this.$emit('medicationupdatelist', this.selectedMedication)
    },
  },
  methods: {
    rowClick(item, row) {
      if (this.rowIndex.includes(row.index)) {
        this.rowIndex = this.rowIndex.filter(rowIndex => rowIndex !== row.index)
        row.select(false)
      } else {
        row.select(true)
        this.rowIndex.push(row.index)
      }
    },
    select(item) {
      item.selected = !item.selected
      if (item.selected === true) {
        this.selectedMedication.push(item)
      } else if (this.selectedMedication.length && item.selected === false) {
        const index = this.selectedMedication.findIndex(medication => medication.RxNumber === item.RxNumber)
        this.selectedMedication.splice(index, 1)
      }
      this.$emit('medicationupdatelist', this.selectedMedication)
      this.$forceUpdate()
    },
  },
}
</script>
