<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <radio-list
        id="v-step-17"
        :state="personalInfo"
        :list="NOFOLLOWUPISSUES"
      ></radio-list>

      <v-flex
        v-if="personalInfo['FOLLOW UP ISSUES']"
        xs12
        md12
      >
        <v-textarea
          v-model="personalInfo['NOTIFICATION ISSUES']"
          label="Notification issues"
          type="text"
        >
        </v-textarea>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/validations'

// import RadioList from '@common/Radiolist/Radiolist';
import RadioList from '@/components/common/Radiolist/Radiolist.vue'

const NOFOLLOWUPISSUES = [
  {
    name: 'NO FOLLOW UP ISSUES',
    label: 'No follow up issues',
    value: 'Yes',
  },
  {
    name: 'FOLLOW UP ISSUES',
    label: 'Follow up issues',
    value: 'Yes',
  },
]

export default {
  components: {
    'radio-list': RadioList,
  },
  props: ['patientProfile'],
  data() {
    return {
      personalInfo: this.patientProfile,
      NOFOLLOWUPISSUES,

      //   rules: {
      //     ...rules,
      //   },
    }
  },
  created() {
    this.personalInfo['NO FOLLOW UP ISSUES'] = 'Yes'
  },

  //   beforeUpdate() {
  //     if (
  //       this.personalInfo["THERAPEUTIC ISSUE"]
  //     ) {
  //       this.personalInfo["NO FOLLOW UP ISSUES"] = "";
  //       this.personalInfo["FOLLOW UP ISSUES"] = "Yes";
  //     // this.personalInfo['NOTIFICATION ISSUES'] ="Therapeutic Issue: "+ this.personalInfo['THERAPEUTIC ISSUE'];
  // // this.personalInfo['NOTIFICAITON ISSUES'] = this.personalInfo['NOTIFICATION ISSUES'] + this.personalInfo['THERAPEUTIC ISSUE SUGGESTED THERAPY'];
  //     } else {
  //       this.personalInfo["NO FOLLOW UP ISSUES"] = "Yes";
  //       this.personalInfo["FOLLOW UP ISSUES"] = "";
  //     }
  //   },
}
</script>

<style scoped></style>
