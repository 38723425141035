<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <v-row>
        <v-col
          xs="6"
          lg="6"
        >
          <!-- <pg-datepicker
                        label="Appointment date"
                        refKey="DATE"
                        :refState="personalInfo"
                        :value="personalInfo['DATE']"
                    >
                    </pg-datepicker> -->
          <v-datetime-picker
            v-model="personalInfo['DATE']"
            label="Appointment Time and Date"
            ref-key="DATE"
            :datetime="personalInfo['DATE']"
            :ref-state="personalInfo"
            @input="onChange"
          ></v-datetime-picker>
        </v-col>
        <!-- <v-col xs="6">
                    <v-text-field v-model="personalInfo['DATE']" label="Appointment Time" :rules="[rules.required]" return-masked-value mask="time" hint="24 hours format">
                    </v-text-field>
                </v-col> -->
      </v-row>
      <v-flex
        id="v-step-1"
        xs12
      >
        <h3 class="body-2">
          Is this patient diabetic?
        </h3>
        <v-radio-group
          v-model="personalInfo['IS DIABETIC']"
          row
        >
          <v-radio
            label="No"
            value=""
          ></v-radio>
          <v-radio
            label="Yes"
            value="true"
          ></v-radio>
        </v-radio-group>
      </v-flex>
      <!-- <v-flex xs12>
                <radio-list :state="personalInfo" :list="CHECKLIST_COLLECTION"></radio-list>
            </v-flex> -->
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/index'

// import Radiolist from '@common/Radiolist/Radiolist';
// import Datepicker from "@common/ui/Form/Datepicker/Datepicker";
// import Radiolist from '../../../components/common/Radiolist/Radiolist';
// import Datepicker from "../../../components/common/ui/Form/Datepicker/Datepicker";
import DateTimePicker from '@/components/common/ui/Form/Datepicker/DateTimePicker.vue'

// const CHECKLIST_COLLECTION = [
//     {
//         name: "MEDSCHECK ANNUAL CHECKBOX ACKNOWLEDGMENET",
//         label: "This is an annual medscheck",
//         value: "Yes"

//     },
//     {
//         name: "MEDSCHECK FOR DIABETES CHECKBOX ACKNOWLEDGMENET",
//         label: "This is a diabetes annual medscheck",
//         value: "Yes"
//     },
//     {
//         name: "MEDSCHECK FOLLOW-UP CHECKBOX ACKNOWLEDGMENET",
//         label: "This is a follow up medscheck",
//         value: "Yes"

//     },
//     {
//         name: "DIABETES EDUCATION FOLLOW-UPMEDSCHECK CHECKBOX ACKNOWLEDGMENET",
//         label: "This is a diabetes follow up medscheck",
//         value: "Yes"

//     },
//     {
//         name: "MEDSCHECK AT HOME CHECKBOX ACKNOWLEDGMENET",
//         label: "This is a medscheck at home",
//         value: "Yes"

//     }
// ];

export default {
    components: {
    // 'radio-list': Radiolist,
    // 'pg-datepicker': Datepicker,
        'v-datetime-picker': DateTimePicker,
    },
    props: ['patientProfile'],
    data() {
        return {
            personalInfo: this.patientProfile,
            rules: {
                ...rules,
            },
        }
    },
    created() {
        this.personalInfo.DATE = `${
            this.personalInfo.stateId
                ? this.personalInfo.DATE
                : this.$moment().subtract(4, 'hours').format('YYYY/MM/DD')
        }`

        // this.personalInfo['DATE'] = `${this.personalInfo.stateId ? this.personalInfo['DATE']:
        // this.personalInfo['APPOINTMENT TIME'] = `${this.personalInfo.stateId ? this.personalInfo['APPOINTMENT TIME'] : this.$moment().subtract(5,'hours').format('HH:mm')}`;
        this.personalInfo['APPOINTMENT TIME'] = `${
            this.personalInfo.stateId
                ? this.personalInfo.DATE.substr(this.personalInfo.DATE.indexOf(' ') + 1)
                : this.$moment().subtract(4, 'hours').format('hh:mm:ss')
        }`
        this.personalInfo['DATE OF BIRTH'] = this.$moment(
            this.personalInfo['DATE OF BIRTH'],
        ).format('YYYY/MM/DD')
    },
    methods: {
        onChange(val) {
            this.personalInfo.DATE = val.substr(0, val.indexOf(' '))
            this.personalInfo['APPOINTMENT TIME'] = val.substr(val.indexOf(' ') + 1)
            this.datetime = val

            // console.log(this.personalInfo['DATE'])
        },
    },
}
</script>

<style scoped>
</style>
