<template>
  <v-stepper
    v-model="stepState"
    vertical
    class="elevation-0"
  >
    <v-stepper-step
      step="1"
      :complete="stepState > 1"
      class="headline"
    >
      Medication selection
    </v-stepper-step>
    <v-stepper-content step="1">
      <v-container
        grid-list-md
        text-xs-left
      >
        <v-layout
          row
          wrap
        >
          <v-flex x12>
            <p
              id="v-step-8"
              class="subheading"
            >
              Please select which medications you would like to include in the medcheck
            </p>
            <medications-selection
              :selected="personalInfo.selectedMedication"
              :medications="medicationsList"
              @medicationupdatelist="updateMedicationList"
            ></medications-selection>
          </v-flex>
        </v-layout>
      </v-container>
    </v-stepper-content>

    <v-stepper-step
      step="2"
      :complete="stepState > 2"
      class="headline"
    >
      Medication list
    </v-stepper-step>
    <v-stepper-content step="2">
      <v-layout
        align-center
        justify-start
        row
        fill-height
      >
        <v-btn
          color="primary"
          @click="onClickButton"
        >
          Back to Medication Selection
        </v-btn>
      </v-layout>
      <v-container
        grid-list-md
        text-xs-left
      >
        <v-layout
          row
          wrap
        >
          <v-flex
            v-for="(medication, n) in this.personalInfo.selectedMedication"
            :key="medication[`RxNumber`]"
            xs12
            class="pa-0 mb-5 mt-2"
          >
            <div
              id="v-step-9"
              class="title"
            >
              <span class="grey--text">{{ n+1 }} )</span>
              {{ medication[`MED`] }}
              <small
                v-if="medication[`MED STR`]"
                class="grey--text"
              >( {{ medication[`MED STR`] }} )</small>
              <v-btn
                v-if="!medication[`MED`]"
                color="error"
                @click.native="removeMed(n)"
              >
                remove
              </v-btn>
            </div>
            <v-checkbox
              v-model="medication[`DISCONTINUED MEDS`]"
              color="primary"
              value="Yes"
              label="Discontinue medication"
              hide-details
              class="mt-2"
              @click.native="$forceUpdate(); discontinueMed()"
            />
            <!-- FIXME forceupdate due to not kicking in v-model -->
            <div v-if="!medication[`DISCONTINUED MEDS`]">
              <medication-form
                :key="medication[`RxNumber`]"
                :medication="medication"
              ></medication-form>
            </div>
            <div v-if="medication[`DISCONTINUED MEDS`]">
              <v-text-field
                v-model="medication[`DISCONTINUED MEDS NOTES`]"
                label="Discontinued medication notes"
                :rules="[rules.required]"
                type="text"
                @input="$forceUpdate(); discontinueMed()"
              ></v-text-field>
            </div>
          </v-flex>
          <!-- <v-flex v-show="selectedMedication.length === 0">
            <p class="subheading">
              Please go back and selected medications
            </p>
          </v-flex>-->
          <v-flex>
            <v-btn
              id="v-step-12"
              color="primary"
              @click.native="personalInfo.selectedMedication.push({}); $forceUpdate();"
            >
              Add medication
            </v-btn>
          </v-flex>
        </v-layout>
      </v-container>
    </v-stepper-content>
  </v-stepper>
</template>

<script>
import moment from 'moment'
import { rules } from '@/util/index'

// import MedicationForm from "@/MedicationForm/MedicationForm";
import MedicationForm from '@/components/common/MedicationForm/MedicationForm.vue'

// import MedicationInterface from "@common/MedicationForm/interface";
import MedicationInterface from '@/components/common/MedicationForm/interface'

// import MedicationsSelection from "@common/ui/Form/Table/MedicationsSelection/MedicationsSelection";
import MedicationsSelection from '../../../components/common/ui/Form/Table/MedicationsSelection/MedicationsSelection.vue'

export default {
  components: {
    'medication-form': MedicationForm,
    'medications-selection': MedicationsSelection,
  },
  props: ['patientProfile', 'medications', 'stepState', 'changeSubStep'],
  data() {
    return {
      personalInfo: this.patientProfile,
      medicationsList: this.patientProfile.medications,

      // selectedMedication: [],
      search: '',
      rules: {
        ...rules,
      },
    }
  },
  created() {
    if (!this.personalInfo.selectedMedication) {
      this.personalInfo.selectedMedication = []
    } else if (this.personalInfo.selectedMedication) {
      return this.personalInfo.selectedMedication
    }
  },
  methods: {
    updateMedicationList(newMedicationList) {
      this.personalInfo.selectedMedication = newMedicationList
    },
    discontinueMed(medication) {
      const discontinuedMeds = this.personalInfo.selectedMedication
        .filter(med => med['DISCONTINUED MEDS'])
        .map(
          med =>
            `${med.MED} ${med['MED STR']} - QTY:${med.QtyDispense}: ${
              med['DISCONTINUED MEDS NOTES']
                ? med['DISCONTINUED MEDS NOTES']
                : 'N/A'
            }`,
        )
        .join('\n')
      this.personalInfo['CLINICALLY RELEVANT DISCONTINUED MEDICATIONS'] =
        discontinuedMeds
      this.$forceUpdate()
    },
    removeMed(index) {
      this.personalInfo.selectedMedication.splice(index, 1)
      this.$forceUpdate()
    },
    onClickButton() {
      this.$emit('changeSubStep', 1)
    },
  },
}
</script>

<style scoped>
th {
  font-weight: bold !important;
  text-transform: uppercase;
  background-color: lightgrey;
}
</style>
