<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-text-field
          v-model="personalInfo['PHARMACY NAME']"
          label="Pharmacy Name"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <address-info :address="addressState"></address-info>

      <v-flex
        xs12
        md4
      >
        <v-text-field
          v-model="personalInfo['PHARMACY TELEPHONE NUMBER']"
          type="tel"
          mask="phone"
          label="Pharmacy telephone number"
          :rules="[rules.required]"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md4
      >
        <v-text-field
          v-model="personalInfo['PHARMACY FAX NUMBER']"
          type="tel"
          mask="phone"
          label="Pharmacy fax number"
          :rules="[rules.required]"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md4
      >
        <v-text-field
          v-model="personalInfo['PHARMACY EMAIL ADDRESS']"
          label="Pharmacy email address"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/index'

import AddressForm from '@/components/common/AddressForm/AddressForm.vue'

export default {
  name: 'PersonalInfo',
  components: {
    'address-info': AddressForm,
  },
  props: ['patientProfile'],
  data() {
    return {
      personalInfo: this.patientProfile,
      addressState: {
        unit: this.patientProfile['PHARMACY UNIT NUMBER'],
        streetNumber: this.patientProfile['PHARMACY STREET NUMBER'],
        streetName: this.patientProfile['PHARMACY STREET NAME'],
        city: this.patientProfile['PHARMACY CITY'],
        province: this.patientProfile['PHARMACY PROVINCE'],
        postalCode: this.patientProfile['PHARMACY POSTAL CODE'],
        pobox: this.patientProfile['PHARMACY POBOX'],
      },
      rules: {
        ...rules,
      },
    }
  },
  watch: {
    addressState: {
      handler(state) {
        this.personalInfo = Object.assign(this.personalInfo, {
          'PHARMACY UNIT NUMBER': state.unit,
          'PHARMACY STREET NUMBER': state.streetNumber,
          'PHARMACY STREET NAME': state.streetName,
          'PHARMACY CITY': state.city,
          'PHARMACY PROVINCE': state.province,
          'PHARMACY POSTAL CODE': state.postalCode,
          'PHARMACY POBOX': state.pobox,
        })
      },
      deep: true,
    },
  },
}
</script>

<style scoped>
</style>
