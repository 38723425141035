<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-expansion-panels multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>Management of medications</v-expansion-panel-header>
            <v-expansion-panel-content :value="personalInfo['REVIEWED MEDS']">
              <!-- <div slot="header" class="title black--text text-sm-left">Management of medications <v-icon color="primary" class="my-0 mx-2" v-show="personalInfo['REVIEWED MEDS']">check</v-icon></div> -->
              <!-- <v-flex xs6 md6> -->
              <check-list
                :state="personalInfo"
                :checklist="CHECKLIST_MANAGEMENT_OF_MEDICATIONS"
                @change="checked('REVIEWED MEDS', CHECKLIST_MANAGEMENT_OF_MEDICATIONS)"
              ></check-list>
              <!-- </v-flex> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Oral medication hypoglycemic use</v-expansion-panel-header>
            <v-expansion-panel-content :value="personalInfo['HYPOGLYCEMIC']">
              <!-- <div slot="header" class="title black--text text-sm-left">
              Oral medication hypoglycemic use
              <v-icon color="primary" class="my-0 mx-2" v-show="personalInfo['HYPOGLYCEMIC']">check</v-icon>
            </div>-->
              <!-- <v-flex xs6 md6> -->
              <radio-list
                :state="personalInfo"
                :list="HYPOGLYCEMIC_LIST"
                @change="checked('HYPOGLYCEMIC', HYPOGLYCEMIC_LIST, false)"
              ></radio-list>
            <!-- </v-flex> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Foot care discussion
            </v-expansion-panel-header>
            <v-expansion-panel-content :value="personalInfo['FOOT CARE DISCUSSION']">
              <!-- <div slot="header" class="title black--text text-sm-left">
              <v-icon
                color="primary"
                class="my-0 mx-2"
                v-show="personalInfo['FOOT CARE DISCUSSION']"
              >check</v-icon>
            </div> -->
              <!-- <v-card> -->
              <!-- <v-card-text class="pt-0"> -->
              <check-list
                :state="personalInfo"
                :checklist="CHECKLIST_FOOT_CARE_DISCUSSION"
                @change="checked('FOOT CARE DISCUSSION', CHECKLIST_FOOT_CARE_DISCUSSION)"
              ></check-list>
              <!-- </v-card-text> -->
            <!-- </v-card> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Proper shoe fit
            </v-expansion-panel-header>

            <v-expansion-panel-content :value="personalInfo['PROPER SHOE FIT']">
              <!-- <div slot="header" class="title black--text text-sm-left">
              <v-icon
                color="primary"
                class="my-0 mx-2"
                v-show="personalInfo['PROPER SHOE FIT']"
              >check</v-icon>
            </div> -->
              <!-- <v-card> -->
              <!-- <v-card-text class="pt-0"> -->
              <check-list
                :state="personalInfo"
                :checklist="CHECKLIST_PROPER_SHOE_FIT"
                @change="checked('PROPER SHOE FIT', CHECKLIST_PROPER_SHOE_FIT)"
              ></check-list>
              <!-- </v-card-text> -->
            <!-- </v-card> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Screening for diabetic neuropathy
            </v-expansion-panel-header>

            <v-expansion-panel-content :value="personalInfo['SCRENNING FOR DN']">
              <!-- <div slot="header" class="title black--text text-sm-left">
              <v-icon
                color="primary"
                class="my-0 mx-2"
                v-show="personalInfo['SCRENNING FOR DN']"
              >check</v-icon>
            </div> -->
              <!-- <v-card> -->
              <!-- <v-card-text class="pt-0"> -->
              <check-list
                :state="personalInfo"
                :checklist="CHECKLIST_SCRENNING_FOR_DN"
                @change="checked('SCRENNING FOR DN', CHECKLIST_SCRENNING_FOR_DN)"
              ></check-list>
              <!-- </v-card-text> -->
            <!-- </v-card> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Blood pressure monitoring
            </v-expansion-panel-header>

            <v-expansion-panel-content :value="personalInfo['BLOOD PRESSURE MONITORING']">
              <!-- <div slot="header" class="title black--text text-sm-left">
              <v-icon
                color="primary"
                class="my-0 mx-2"
                v-show="personalInfo['BLOOD PRESSURE MONITORING']"
              >check</v-icon>
            </div> -->
              <!-- <v-card> -->
              <!-- <v-card-text class="pt-0"> -->
              <check-list
                :state="personalInfo"
                :checklist="CHECKLIST_BLOOD_PRESSURE_MONITORING"
                @change="checked('BLOOD PRESSURE MONITORING', CHECKLIST_BLOOD_PRESSURE_MONITORING)"
              ></check-list>
              <!-- </v-card-text> -->
            <!-- </v-card> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Cardiovascular risk factors
            </v-expansion-panel-header>

            <v-expansion-panel-content :value="personalInfo['CV AND OTHER RISK FACTORS']">
              <!-- <div slot="header" class="title black--text text-sm-left">
              <v-icon
                color="primary"
                class="my-0 mx-2"
                v-show="personalInfo['CV AND OTHER RISK FACTORS']"
              >check</v-icon>
            </div> -->
              <!-- <v-card> -->
              <!-- <v-card-text class="pt-0"> -->
              <check-list
                :state="personalInfo"
                :checklist="CHECKLIST_CV"
                @change="checked('CV AND OTHER RISK FACTORS', CHECKLIST_CV)"
              ></check-list>
              <!-- </v-card-text> -->
            <!-- </v-card> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Mental health assessment
            </v-expansion-panel-header>
            <v-expansion-panel-content :value="personalInfo['MENTAL HEALTH ASSESSMENT']">
              <!-- <div slot="header" class="title black--text text-sm-left">
              <v-icon
                color="primary"
                class="my-0 mx-2"
                v-show="personalInfo['MENTAL HEALTH ASSESSMENT']"
              >check</v-icon>
            </div> -->
              <!-- <v-card> -->
              <!-- <v-card-text class="pt-0"> -->
              <check-list
                :state="personalInfo"
                :checklist="MENTALHEALTHASSESSMENT"
                @change="checked('MENTAL HEALTH ASSESSMENT', MENTALHEALTHASSESSMENT)"
              ></check-list>
              <!-- </v-card-text> -->
            <!-- </v-card> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Erectile dysfunction/ Sexual Health
            </v-expansion-panel-header>
            <v-expansion-panel-content
              v-if="personalInfo['GENDER'] == 'M'"
              :value="personalInfo['ERRECTILE DYFUNCTION']"
            >
              <!-- <div slot="header" class="title black--text text-sm-left">
              <v-icon
                color="primary"
                class="my-0 mx-2"
                v-show="personalInfo['ERRECTILE DYFUNCTION']"
              >check</v-icon>
            </div> -->
              <!-- <v-card> -->
              <!-- <v-card-text class="pt-0"> -->
              <check-list
                :state="personalInfo"
                :checklist="ERRECTILEDYFUNCTION"
                @change="checked('ERRECTILE DYFUNCTION', ERRECTILEDYFUNCTION)"
              ></check-list>
              <!-- </v-card-text> -->
            <!-- </v-card> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Lifestyle diet
            </v-expansion-panel-header>
            <v-expansion-panel-content :value="personalInfo['LIFESTYLE DIET']">
              <!-- <div slot="header" class="title black--text text-sm-left">
              <v-icon
                color="primary"
                class="my-0 mx-2"
                v-show="personalInfo['LIFESTYLE DIET']"
              >check</v-icon>
            </div> -->
              <!-- <v-card> -->
              <!-- <v-card-text class="pt-0"> -->
              <check-list
                :state="personalInfo"
                :checklist="LIFESTYLEDIET"
                @change="checked('LIFESTYLE DIET', LIFESTYLEDIET)"
              ></check-list>
              <!-- </v-card-text> -->
            <!-- </v-card> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Lifestyle stress reduction
            </v-expansion-panel-header>
            <v-expansion-panel-content :value="personalInfo['LIFESTYLE STRESS REDUCTION']">
              <!-- <div slot="header" class="title black--text text-sm-left">
              <v-icon
                color="primary"
                class="my-0 mx-2"
                v-show="personalInfo['LIFESTYLE STRESS REDUCTION']"
              >check</v-icon>
            </div> -->
              <!-- <v-card> -->
              <!-- <v-card-text class="pt-0"> -->
              <check-list
                :state="personalInfo"
                :checklist="LIFESTYLESTRESSREDUCTION"
                @change="checked('LIFESTYLE STRESS REDUCTION', LIFESTYLESTRESSREDUCTION)"
              ></check-list>
              <!-- </v-card-text> -->
            <!-- </v-card> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Lifestyle excercise
            </v-expansion-panel-header>
            <v-expansion-panel-content :value="personalInfo['LIFESTYLE EXCERCISE']">
              <!-- <div slot="header" class="title black--text text-sm-left">
              <v-icon
                color="primary"
                class="my-0 mx-2"
                v-show="personalInfo['LIFESTYLE EXCERCISE']"
              >check</v-icon>
            </div> -->
              <!-- <v-card> -->
              <!-- <v-card-text class="pt-0"> -->
              <check-list
                :state="personalInfo"
                :checklist="LIFESTYLEEXCERCISE"
                @change="checked('LIFESTYLE EXCERCISE', LIFESTYLEEXCERCISE)"
              ></check-list>
              <!-- </v-card-text> -->
            <!-- </v-card> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Eye health and reminder
            </v-expansion-panel-header>

            <v-expansion-panel-content :value="personalInfo['EYE HEALTH AND REMINDER']">
              <!-- <div slot="header" class="title black--text text-sm-left">
              <v-icon
                color="primary"
                class="my-0 mx-2"
                v-show="personalInfo['EYE HEALTH AND REMINDER']"
              >check</v-icon>
            </div> -->
              <!-- <v-card> -->
              <!-- <v-card-text class="pt-0"> -->
              <check-list
                :state="personalInfo"
                :checklist="EYEHEALTHCHECK"
                @change="checked('EYE HEALTH AND REMINDER', EYEHEALTHCHECK)"
              ></check-list>
              <!-- </v-card-text> -->
            <!-- </v-card> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Dental hygine
            </v-expansion-panel-header>
            <v-expansion-panel-content :value="personalInfo['DENTAL HYGIENE']">
              <!-- <div slot="header" class="title black--text text-sm-left">
              <v-icon
                color="primary"
                class="my-0 mx-2"
                v-show="personalInfo['DENTAL HYGEINE']"
              >check</v-icon>
            </div> -->
              <!-- <v-card> -->
              <!-- <v-card-text class="pt-0"> -->
              <check-list
                :state="personalInfo"
                :checklist="DENTALHYGIENE"
                @change="checked('DENTAL HYGIENE', DENTALHYGIENE)"
              ></check-list>
              <!-- </v-card-text> -->
            <!-- </v-card> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Immunizations
            </v-expansion-panel-header>
            <v-expansion-panel-content :value="personalInfo['IMMUNIZATIONS']">
              <!-- <div slot="header" class="title black--text text-sm-left">
              <v-icon color="primary" class="my-0 mx-2" v-show="personalInfo['IMMUNIZATIONS']">check</v-icon>
            </div> -->
              <!-- <v-card> -->
              <!-- <v-card-text class="pt-0"> -->
              <v-layout
                align-center
                justify-start
                row
              >
                <v-flex xs4>
                  <v-checkbox
                    v-model="personalInfo['FLU VACCINE']"
                    color="primary"
                    label="Flu vaccine"
                    :rules="[rules.required]"
                    true-value="Yes"
                    false-value
                    hide-details
                  ></v-checkbox>
                </v-flex>

                <v-flex
                  v-show="personalInfo['FLU VACCINE']"
                  xs4
                >
                  <v-checkbox
                    v-model="personalInfo['FLU YES']"
                    label="Flu Yes"
                    :rules="[rules.required]"
                    type="checkbox"
                    true-value="Yes"
                    false-value
                    hide-details
                  ></v-checkbox>
                </v-flex>
                <v-flex
                  v-show="personalInfo['FLU VACCINE']"
                  xs4
                >
                  <v-checkbox
                    v-model="personalInfo['FLU DR REFERRAL']"
                    label="Flu Dr Referral"
                    :rules="[rules.required]"
                    type="checkbox"
                    true-value="Yes"
                    false-value
                    hide-details
                  ></v-checkbox>
                </v-flex>
              </v-layout>
              <!-- </v-card-text> -->

              <!-- <check-list :state="personalInfo"
                                    @change="checkImmunizations('IMMUNIZATIONS')"
                                    :checklist="FLUVACCINE"
              ></check-list>-->

              <!-- <check-list :state="personalInfo"
                                    @change="checkImmunizations('IMMUNIZATIONS')"
                                    :checklist="PNEUMOCOCCALVACCINE"
              ></check-list>-->

              <!-- <v-card-text class="pt-0"> -->
              <v-layout
                align-center
                justify-start
                row
              >
                <v-flex xs4>
                  <v-checkbox
                    v-model="personalInfo['PNEUMOCOCCAL VACCINE']"
                    color="primary"
                    label="Pneumococcal vaccine"
                    :rules="[rules.required]"
                    true-value="Yes"
                    false-value
                    hide-details
                  ></v-checkbox>
                </v-flex>

                <v-flex
                  v-show="personalInfo['PNEUMOCOCCAL VACCINE']"
                  xs4
                >
                  <v-checkbox
                    v-model="personalInfo['PENUMOCOCCAL YES']"
                    label="Pneumnooccal Yes"
                    :rules="[rules.required]"
                    type="checkbox"
                    true-value="Yes"
                    false-value
                    hide-details
                  ></v-checkbox>
                </v-flex>
                <v-flex
                  v-show="personalInfo['PNEUMOCOCCAL VACCINE']"
                  xs4
                >
                  <v-checkbox
                    v-model="personalInfo['PNEUMOCOCCAL PHYSICIAN']"
                    label="Pneumococcal Dr Referral"
                    :rules="[rules.required]"
                    type="checkbox"
                    true-value="Yes"
                    false-value
                    hide-details
                  ></v-checkbox>
                </v-flex>
              </v-layout>
              <!-- </v-card-text> -->

              <!-- <check-list :state="personalInfo"
                                    @change="checkImmunizations('IMMUNIZATIONS')"
                                    :checklist="OTHERIMMUNIZATIONS"
              ></check-list>-->

              <!-- <v-card-text class="pt-0"> -->
              <v-layout
                align-center
                justify-start
                row
              >
                <v-flex xs4>
                  <v-checkbox
                    v-model="personalInfo['OTHER IMMUNIZATIONS']"
                    color="primary"
                    label="Other Immunizations"
                    :rules="[rules.required]"
                    true-value="Yes"
                    false-value
                    hide-details
                  ></v-checkbox>
                </v-flex>

                <v-flex
                  v-show="personalInfo['OTHER IMMUNIZATIONS']"
                  xs4
                >
                  <v-checkbox
                    v-model="personalInfo['SHINGLES']"
                    label="Shingles"
                    :rules="[rules.required]"
                    type="checkbox"
                    true-value="Yes"
                    false-value
                    hide-details
                  ></v-checkbox>
                </v-flex>
                <v-flex
                  v-show="personalInfo['OTHER IMMUNIZATIONS']"
                  xs4
                >
                  <v-text-field
                    v-model="personalInfo['VACCINE OTHER NOTE']"
                    label="Other Vaccine notes"
                    :rules="[rules.required]"
                    type="text"
                    hide-details
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <!-- </v-card-text> -->

              <!-- <check-list :state="personalInfo"
                                    @change="checkImmunizations('IMMUNIZATIONS')"
                                    :checklist="VACCINEOTHERNOTE"
              ></check-list>-->
            <!-- </v-card> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Driving guidelines
            </v-expansion-panel-header>
            <v-expansion-panel-content :value="personalInfo['DRIVING GUIDELINES']">
              <!-- <div slot="header" class="title black--text text-sm-left">
              <v-icon
                color="primary"
                class="my-0 mx-2"
                v-show="personalInfo['DRIVING GUIDELINES']"
              >check</v-icon>
            </div> -->
              <!-- <v-card> -->
              <!-- <v-card-text class="pt-0"> -->
              <check-list
                :state="personalInfo"
                :checklist="DRIVINGGUIDELINES"
                @change="checked('DRIVING GUIDELINES', DRIVINGGUIDELINES)"
              ></check-list>
              <!-- </v-card-text> -->
            <!-- </v-card> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Travelling with diabetes
            </v-expansion-panel-header>
            <v-expansion-panel-content :value="personalInfo['TRAVELLING WITH DIABETES CHECKBOX']">
              <!-- <div slot="header" class="title black--text text-sm-left">
              <v-icon
                color="primary"
                class="my-0 mx-2"
                v-show="personalInfo['TRAVELLING WITH DIABETES CHECKBOX']"
              >check</v-icon>
            </div> -->
              <!-- <v-card> -->
              <!-- <v-card-text class="pt-0"> -->
              <check-list
                :state="personalInfo"
                :checklist="TRAVELLINGWITHDIABETESCHECKBOX"
                @change="checked('TRAVELLING WITH DIABETES CHECKBOX', TRAVELLINGWITHDIABETESCHECKBOX)"
              ></check-list>
              <!-- </v-card-text> -->
            <!-- </v-card> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/index'
import Checklist from '@/components/common/Checklist/Checklist'
import Radiolist from '@/components/common/Radiolist/Radiolist'

const CHECKLIST_MANAGEMENT_OF_MEDICATIONS = [
  // {
  //     name: 'REVIEWED MEDS',
  //     label: 'Reviewed meds',
  //     value: 'Yes',
  //     checked: true
  // },
  {
    name: 'COMPLIANCE PACKAGING',
    label: 'Compliance packaging',
    value: 'Yes',
  },
  {
    name: 'SNAP CAPS',
    label: 'Snap caps',
    value: 'Yes',
  },
]

const CHECKLIST_FOOT_CARE_DISCUSSION = [
  // {
  //     name: 'FOOT CARE DISCUSSION',
  //     label: 'Foot care discussion',
  //     value: 'Yes'
  // },
  {
    name: 'FOOT CARE DOING WELL',
    label: 'Doing well',
    value: 'Yes',
  },
  {
    name: 'FOOT CARE ULCERATION',
    label: 'Ulceration',
    value: 'Yes',
  },
  {
    name: 'FOOT CARE PAIN',
    label: 'Pain',
    value: 'Yes',
  },
  {
    name: 'FOOT CARE NUMBNESS',
    label: 'Numbness',
    value: 'Yes',
  },
  {
    name: 'FOOT CARE REFER TO PHYSICAN',
    label: 'Refer to physican',
    value: 'Yes',
  },
]

const CHECKLIST_SCRENNING_FOR_DN = [
  // {
  //     name: 'SCRENNING FOR DN',
  //     label: 'Screnning for dn',
  //     value: 'Yes'
  // },
  {
    name: 'DN DOING WELL',
    label: 'Doing well',
    value: 'Yes',
  },
  {
    name: 'DN REFER TO PHYSICAN',
    label: 'Refer to physican',
    value: 'Yes',
  },
]

const CHECKLIST_FOOT_CONDITIONS_ULCERS = [
  {
    name: 'FOOT CONDITIONS ULCERS',
    label: 'Foot conditions ulcers',
    value: 'Yes',
  },
  {
    name: 'FOOT CONDITIONS DOING WELL',
    label: 'Doing well',
    value: 'Yes',
  },
  {
    name: 'FOOT CONDITIONS REFER',
    label: 'Refer',
    value: 'Yes',
  },
]

const CHECKLIST_PROPER_SHOE_FIT = [
  // {
  //     name: 'PROPER SHOE FIT',
  //     label: 'Proper shoe fit',
  //     value: 'Yes'
  // },
  {
    name: 'SHOE FIT DOING WELL',
    label: 'Doing well',
    value: 'Yes',
  },
  {
    name: 'SHOE RECOMMENDATION',
    label: 'Recommendation',
    value: 'Yes',
  },
]

const CHECKLIST_BLOOD_PRESSURE_MONITORING = [
  // {
  //     name: 'BLOOD PRESSURE MONITORING',
  //     label: 'Blood pressure monitoring',
  //     value: 'Yes'
  // },
  {
    name: 'BP MONITORED REGULARLY',
    label: 'Monitored regularly',
    value: 'Yes',
  },
  {
    name: 'BP ADVISED TO MONITOR',
    label: 'Advised to monitor',
    value: 'Yes',
  },
]

const CHECKLIST_CV = [
  // {
  //     name: 'CV AND OTHER RISK FACTORS',
  //     label: 'Cv and other risk factors',
  //     value: 'Yes'
  // },
  {
    name: 'UNCONTROLLED CHOLESTEROL LEVELS',
    label: 'Uncontrolled cholesterol levels',
    value: 'Yes',
  },
  {
    name: 'UNCONTROLLED GLUCOSE LEVELS',
    label: 'Uncontrolled glucose levels',
    value: 'Yes',
  },
  {
    name: 'OVERWEIGHT CHECKBOX',
    label: 'Overweight',
    value: 'Yes',
  },
]

const MENTALHEALTHASSESSMENT = [
  // {
  //     name: 'MENTAL HEALTH ASSESSMENT',
  //     label: 'Mental health assessment',
  //     value: 'Yes'
  // },
  {
    name: 'MENTAL HEALTH DOING WELL',
    label: 'Doing well',
    value: 'Yes',
  },
  {
    name: 'MENTAL HEALTH REFER TO PHYSICAN',
    label: 'Refer to physican',
    value: 'Yes',
  },
]

const ERRECTILEDYFUNCTION = [
  // {
  //     name: "ERRECTILE DYFUNCTION",
  //     label: "Errectile dyfunction",
  //     value: "Yes"
  // },
  {
    name: 'ERRECTILE DYFUNCTION DOING WELL',
    label: 'Doing well',
    value: 'Yes',
  },
  {
    name: 'ERRECTILE DYFUNCTION REFERRAL',
    label: 'Referral',
    value: 'Yes',
  },
]

// FIXME
//    const LIFESTYLECHECKBOX = [
//         {
//             name: "LIFESTYLE CHECK BOX",
//             label: "Lifestyle check box",
//             value: "Yes"

//         }
//     ];

const LIFESTYLEDIET = [
  // {
  //     name: "LIFESTYLE DIET",
  //     label: "Lifestyle diet",
  //     value: "Yes"

  // },
  {
    name: 'DIET WILL IMPROVE',
    label: 'Will improve',
    value: 'Yes',
  },
  {
    name: 'DIET WILL MAINTAIN',
    label: 'Will maintain',
    value: 'Yes',
  },
  {
    name: 'DIET SODIUM RESTIRCTED',
    label: 'Sodium restircted',
    value: 'Yes',
  },
]

//    const DIETDROPDOWN = [
//         {
//             name: "DIET DROPDOWN",
//             label: "Diet dropdown",
//             value: "Yes"

//         }
//     ];

const LIFESTYLESTRESSREDUCTION = [
  // {
  //     name: "LIFESTYLE STRESS REDUCTION",
  //     label: "Lifestyle stress reduction",
  //     value: "Yes"
  // },
  {
    name: 'LIFESTYLE MEDITATION',
    label: 'Lifestyle meditation',
    value: 'Yes',
  },
  {
    name: 'LIFESTYLE RELAXATION TECHNIQUES',
    label: 'Lifestyle relaxation techniques',
    value: 'Yes',
  },
  {
    name: 'REFER TO PHYSICIAN STRESS REDUCTION',
    label: 'Refer to physician stress reduction',
    value: 'Yes',
  },
]

const LIFESTYLEEXCERCISE = [
  // {
  //     name: "LIFESTYLE EXCERCISE",
  //     label: "Lifestyle excercise",
  //     value: "Yes"
  // },
  {
    name: 'EXERCISE WILL INCREASE',
    label: 'Will increase',
    value: 'Yes',
  },
  {
    name: 'EXERCISE WILL MAINTAIN',
    label: 'Will maintain',
    value: 'Yes',
  },
  {
    name: 'EXERCISE UNABLE',
    label: 'Unable',
    value: 'Yes',
  },
]

const EYEHEALTHCHECK = [
  // {
  //     name: "EYE HEALTH AND REMINDER",
  //     label: "Eye health and reminder",
  //     value: "Yes"

  // },
  {
    name: 'EYE HEALTH WELL',
    label: 'Doing well',
    value: 'Yes',
  },
  {
    name: 'EYE HEALTH FOLLOW UP',
    label: 'Follow up',
    value: 'Yes',
  },
]

const DENTALHYGIENE = [
  // {
  //     name: "DENTAL HYGIENE",
  //     label: "Dental hygiene",
  //     value: "Yes"

  // },
  {
    name: 'DENTAL HYGIENE DOING WELL',
    label: 'Doing well',
    value: 'Yes',
  },
  {
    name: 'DENTAL FOLLOW UP',
    label: 'Follow up',
    value: 'Yes',
  },
]

// const IMMUNIZATIONS = [
//     {
//         name: "IMMUNIZATIONS",
//         label: "Immunizations",
//         value: "Yes"

//     }
// ];

const FLUVACCINE = [
  {
    name: 'FLU VACCINE',
    label: 'Flu vaccine',
    value: 'Yes',
  },
  {
    name: 'FLU YES',
    label: 'Flu yes',
    value: 'Yes',
  },
  {
    name: 'FLU DR REFERRAL',
    label: 'Flu dr referral',
    value: 'Yes',
  },
]

const PNEUMOCOCCALVACCINE = [
  {
    name: 'PNEUMOCOCCAL VACCINE',
    label: 'Pneumococcal vaccine',
    value: 'Yes',
  },
  {
    name: 'PENUMOCOCCAL YES',
    label: 'Penumococcal yes',
    value: 'Yes',
  },
  {
    name: 'PNEUMOCOCCAL PHYSICIAN',
    label: 'Pneumococcal physician',
    value: 'Yes',
  },
]

const OTHERIMMUNIZATIONS = [
  {
    name: 'OTHER IMMUNIZATIONS',
    label: 'Other immunizations',
    value: 'Yes',
  },
  {
    name: 'SHINGLES',
    label: 'Shingles',
    value: 'Yes',
  },
]

const DRIVINGGUIDELINES = [
  // {
  //     name: "DRIVING GUIDELINES",
  //     label: "Driving guidelines",
  //     value: "Yes"

  // },
  {
    name: 'DRIVING NA',
    label: 'N/A',
    value: 'Yes',
  },
  {
    name: 'DRIVING DOING WELL',
    label: 'Doing well',
    value: 'Yes',
  },
  {
    name: 'DRIVING FOLLOW UP',
    label: 'Follow up',
    value: 'Yes',
  },
]

const TRAVELLINGWITHDIABETESCHECKBOX = [
  // {
  //     name: "TRAVELLING WITH DIABETES CHECKBOX",
  //     label: "Travelling with diabetes",
  //     value: "Yes"
  // },
  {
    name: 'TRAVELLING DICUSSED',
    label: 'Travelling dicussed',
    value: 'Yes',
  },
]

const HYPOGLYCEMIC_LIST = [
  {
    name: 'HYPOGLYCEMIC YES',
    label: 'Yes',
    value: 'Yes',
  },
  {
    name: 'HYPOGLYCEMIC NO',
    label: 'No',
    value: 'Yes',
  },
]

export default {
  components: {
    'check-list': Checklist,
    'radio-list': Radiolist,
  },
  props: ['patientProfile'],
  data() {
    return {
      personalInfo: this.patientProfile,
      CHECKLIST_MANAGEMENT_OF_MEDICATIONS,
      CHECKLIST_FOOT_CARE_DISCUSSION,
      CHECKLIST_SCRENNING_FOR_DN,
      CHECKLIST_FOOT_CONDITIONS_ULCERS,
      CHECKLIST_PROPER_SHOE_FIT,
      CHECKLIST_BLOOD_PRESSURE_MONITORING,
      CHECKLIST_CV,
      MENTALHEALTHASSESSMENT,
      ERRECTILEDYFUNCTION,
      LIFESTYLEDIET,
      LIFESTYLESTRESSREDUCTION,
      LIFESTYLEEXCERCISE,
      EYEHEALTHCHECK,
      DENTALHYGIENE,
      FLUVACCINE,
      PNEUMOCOCCALVACCINE,
      OTHERIMMUNIZATIONS,
      DRIVINGGUIDELINES,
      TRAVELLINGWITHDIABETESCHECKBOX,
      HYPOGLYCEMIC_LIST,
      rules: {
        ...rules,
      },
    }
  },
  methods: {
    checked(key, list, nullify = true) {
      if (nullify) {
        this.personalInfo[key] = null
      }
      const isChecked = list.findIndex(ref => this.personalInfo[ref.name]) != -1

      if (isChecked) {
        this.personalInfo[key] = 'Yes'
      }

      this.$forceUpdate()
    },
    isCheckedText(checkboxKey, textKey) {
      this.personalInfo[checkboxKey] = this.personalInfo[textKey].length
        ? 'Yes'
        : null
    },

    // exceptional case for sub categories all
    checkImmunizations(key) {
      const list = [
        ...FLUVACCINE,
        ...PNEUMOCOCCALVACCINE,
        ...OTHERIMMUNIZATIONS,
      ]

      // VACCINE OTHER NOTE is a text field
      const isChecked =
        list.findIndex(ref => this.personalInfo[ref.name]) != -1 ||
        this.personalInfo['VACCINE OTHER NOTE']

      if (isChecked) {
        this.personalInfo[key] = 'Yes'
      } else {
        this.personalInfo[key] = null
      }

      this.$forceUpdate()
    },
  },
}
</script>

<style scoped>
.expansion-panel__container:first-child {
  border-top: 1px solid #efefef !important;
}
</style>
