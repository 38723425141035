<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <!-- <v-flex xs6>
                <v-text-field disabled label="Medscheck Reminder" prepend-icon="event" :rules="[rules.required]" readonly v-model="personalInfo.MedsCheckReminder" :value="personalInfo.MedsCheckReminder | moment('YYYY-MM-DD')">
                </v-text-field>
            </v-flex>
            <v-flex xs6>
                <v-text-field disabled label="Last medication reviewed" prepend-icon="event" :rules="[rules.required]" readonly v-model="personalInfo.LastVisit" :value="personalInfo.LastVisit | moment('YYYY-MM-DD')">
                </v-text-field>
      </v-flex>-->

      <v-flex
        xs12
        md6
      >
        <v-text-field
          v-model="personalInfo['FIRST NAME']"
          label="Patient first name"
          :rules="[rules.required]"
          type="text"
        ></v-text-field>
      </v-flex>

      <v-flex
        xs12
        md6
      >
        <v-text-field
          v-model="personalInfo['LAST NAME']"
          label="Patient last name"
          :rules="[rules.required]"
          type="text"
        ></v-text-field>
      </v-flex>

      <v-flex
        v-if="!!showDOB"
        xs12
        md6
      >
        <v-menu
          v-model="menu"
          :close-on-content-click="false"
          transition="scale-transition"
          offset-y
          :nudge-right="40"
          max-width="290px"
          min-width="290px"
        >
          <template v-slot:activator="{on}">
            <v-text-field
              slot="activator"
              label="Date of birth"
              prepend-icon="event"
              :rules="[rules.required]"
              readonly
              :value="personalInfo['DATE OF BIRTH'] | moment('YYYY/MM/DD')"
              v-on="on"
            ></v-text-field>
          </template>
          <v-date-picker
            v-model="personalInfo.DOB"
            no-title
            scrollable
            actions
          >
            <template slot-scope="{ save, cancel }">
              <v-card-actions>
                <v-spacer></v-spacer>
                <v-btn
                  flat
                  color="primary"
                  @click="cancel"
                >
                  Cancel
                </v-btn>
                <v-btn
                  flat
                  color="primary"
                  @click="save"
                >
                  OK
                </v-btn>
              </v-card-actions>
            </template>
          </v-date-picker>
        </v-menu>
      </v-flex>

      <v-flex
        v-if="!!showOhip"
        xs12
        md6
      >
        <v-text-field
          v-model="personalInfo['HEALTH CARD NUMBER']"
          label="Patient Health Card #"
          :rules="[rules.required]"
          type="text"
        ></v-text-field>
      </v-flex>

      <v-flex
        v-if="!!showGender"
        xs12
        md12
      >
        <h3 class="body-2">
          Gender
        </h3>
        <!-- <v-text-field
          label="GENDER"
          :rules="[rules.required]"
          type="text"
          v-model="personalInfo['GENDER']"
        ></v-text-field> -->
        <v-radio-group
          v-model="personalInfo['GENDER']"
          row
          block
        >
          <v-radio
            label="Male"
            value="M"
          ></v-radio>
          <v-radio
            label="Female"
            value="F"
          ></v-radio>
        </v-radio-group>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/index'

export default {
  name: 'PatientInfo',
  props: ['patientProfile', 'showDOB', 'showGender', 'showOhip'],
  data() {
    return {
      personalInfo: this.patientProfile,
      menu: '',
      rules: {
        ...rules,
      },
    }
  },
}
</script>

<style scoped>
</style>
