<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <!-- <v-flex xs12 md12>
                <v-checkbox
                    color="primary"
                    label="Other"
                    v-model="personalInfo['REFERRALS OTHER']"
                    true-value="Yes"
                    false-value=""
                    hide-details
                ></v-checkbox>
            </v-flex>

            <v-flex xs12 md12 v-show="personalInfo['REFERRALS OTHER']">
                <v-text-field
                    v-model="personalInfo['REFERRAL  OTHER']"
                    label="Name of other"
                    :rules="[rules.required]"
                    type="text"
                >
                </v-text-field>
            </v-flex>

            ----- -->

      <v-flex
        xs12
        md12
      >
        <div id="v-step-21"></div>
        <v-checkbox
          v-model="personalInfo['WEIGHT CHECK BOX']"
          color="primary"
          label="Patient Characteristics"
          true-value="Yes"
          false-value=""
          hide-details
        ></v-checkbox>
      </v-flex>

      <v-flex
        v-show="personalInfo['WEIGHT CHECK BOX']"
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['WEIGHT NOTE']"
          label="Weight"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        v-show="personalInfo['WEIGHT CHECK BOX']"
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['HEIGHT NOTE']"
          label="Height"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        v-show="personalInfo['WEIGHT CHECK BOX']"
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['WAIST CIRCUMFERENCE']"
          label="Waist circumference"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        v-show="personalInfo['WEIGHT CHECK BOX']"
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['BMI']"
          label="BMI"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md12
      >
        <v-checkbox
          v-model="personalInfo['COGNITIVE FUNCTION/LEARNING IMPAIRMENTS']"
          color="primary"
          label="Cognitive function/learning impairments"
          true-value="Yes"
          false-value=""
          hide-details
        ></v-checkbox>
      </v-flex>

      <v-flex
        v-show="personalInfo['COGNITIVE FUNCTION/LEARNING IMPAIRMENTS']"
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['COGNITIVE FUNCTION/LEARNING IMPAIRMENTS NOTES']"
          label="Cognitive function/learning impairments notes"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md12
      >
        <v-checkbox
          v-model="personalInfo['MEDCHECK FOR DIABETES AVAILABLE']"
          color="primary"
          label="Previous diabetic medcheck on file"
          true-value="Yes"
          false-value=""
          hide-details
        ></v-checkbox>
      </v-flex>

      <v-flex
        xs12
        md12
      >
        <v-checkbox
          v-model="personalInfo['DTP IDENTIFIED AND RESOLVED']"
          color="primary"
          label="Drug therapy problem identified and resolved"
          true-value="Yes"
          false-value=""
          hide-details
        ></v-checkbox>
      </v-flex>

      <v-flex
        v-show="personalInfo['DTP IDENTIFIED AND RESOLVED']"
        xs12
        md12
      >
        <v-checkbox
          v-model="personalInfo['DTP SEE ATTACHED DRUG NOTES']"
          color="primary"
          label="Drug therapy problem see attached drug notes"
          true-value="Yes"
          false-value=""
          hide-details
        ></v-checkbox>
      </v-flex>

      <v-flex
        v-show="personalInfo['DTP SEE ATTACHED DRUG NOTES']"
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['DTP IDENTIFIED AND RESOLVED NOTES']"
          label="Drug therapy problem identified and resolved notes"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md12
      >
        <v-checkbox
          v-model="personalInfo['DEITARY CONCERNCS']"
          color="primary"
          label="Dietary Concerns"
          true-value="Yes"
          false-value=""
          hide-details
        ></v-checkbox>
        <v-text-field
          v-show="personalInfo['DEITARY CONCERNCS']"
          v-model="personalInfo['DIETARY CONCERNS NOTES']"
          label="Dietary concerns notes"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <!-- <v-flex xs12 md12>
                <v-checkbox
                    color="primary"
                    label="Lifestyle information other"
                    v-model="personalInfo['LIFESTYLE INFORMATION OTHER']"
                    true-value="Yes"
                    false-value=""
                    hide-details
                ></v-checkbox>
            </v-flex>

            <v-flex xs12 md12 v-show="personalInfo['LIFESTYLE INFORMATION OTHER']">
                <v-text-field
                    v-model="personalInfo['LIFESTYLE INFORMATION OTHER NOTES']"
                    label="Lifestyle information other notes"
                    :rules="[rules.required]"
                    type="text"
                >
                </v-text-field>
            </v-flex> -->

      <!--- second section -->

      <v-flex
        xs12
        md12
      >
        <v-combobox
          id="v-step-22"
          v-model="personalInfo['STAGE OF READINESS FOR CHANGE']"
          chips
          multiple
          :items="STAGEOFREADINESSFORCHANGE"
          label="Stage of readiness for change"
        ></v-combobox>
      </v-flex>

      <v-flex
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['STAGE OF READINESS FOR CHANGE NOTES']"
          label="Stage of readiness for change notes"
          :rules="[rules.required]"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md12
      >
        <v-combobox
          v-model="personalInfo['SUMMARY PATIENT GOALS']"
          chips
          multiple
          :items="SUMMARYPATIENTGOALS"
          label="Summary patient goals"
        ></v-combobox>
      </v-flex>

      <v-flex
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['COMMENTS ON DIABETIC DRUG THERAPY']"
          label="Comments on diabetic drug therapy"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <!-- <v-flex xs12>
                <v-subheader class="title black--text text-sm-left">
                    Insulin use
                </v-subheader>
                <radio-list :state="personalInfo" :list="INSULIN_LIST"></radio-list>
            </v-flex>

            <v-flex xs12>
                <v-subheader class="title black--text text-sm-left">
                    Hypoglycemic use
                </v-subheader>
                <radio-list :state="personalInfo" :list="HYPOGLYCEMIC_LIST"></radio-list>
            </v-flex> -->

      <v-flex
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['PHARMACISTS COMMENTS ON DIABETIC DRUG THERAPY']"
          label="Pharmacists comments on diabetic drug therapy"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        sm2
      >
        <div id="v-step-23"></div>
        <v-checkbox
          v-model="personalInfo['FASTING PLASMA GLUCOSE']"
          color="primary"
          label="FPG"
          true-value="Yes"
          false-value=""
          hide-details
          class="pt-3"
        ></v-checkbox>
      </v-flex>

      <v-flex
        v-show="personalInfo['FASTING PLASMA GLUCOSE']"
        xs12
        sm10
      >
        <v-text-field
          v-model="personalInfo['FPG NOTE']"
          label="Recent FPG"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        class="my-0 py-0"
      ></v-flex>

      <v-flex
        xs12
        sm2
      >
        <v-checkbox
          v-model="personalInfo['HBA1C']"
          color="primary"
          label="HBA1C"
          true-value="Yes"
          false-value=""
          hide-details
          class="pt-3"
        ></v-checkbox>
      </v-flex>

      <v-flex
        v-show="personalInfo['HBA1C']"
        xs12
        sm10
      >
        <v-text-field
          v-model="personalInfo['HBA1C NOTES']"
          label="Recent HBA1C"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        class="my-0 py-0"
      ></v-flex>

      <v-flex
        xs12
        sm2
      >
        <v-checkbox
          v-model="personalInfo['BLOOD PRESSURE AVERAGE']"
          color="primary"
          label="BP"
          true-value="Yes"
          false-value=""
          hide-details
          class="pt-3"
        ></v-checkbox>
      </v-flex>

      <v-flex
        v-show="personalInfo['BLOOD PRESSURE AVERAGE']"
        xs12
        sm10
      >
        <v-text-field
          v-model="personalInfo['BLOOD PRESSURE NOTE']"
          label="Recent BP"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md12
      >
        <v-checkbox
          v-model="personalInfo['TOTAL CHOLESTEROL']"
          color="primary"
          label="TOTAL CHOLESTEROL"
          true-value="Yes"
          false-value=""
          hide-details
        ></v-checkbox>
      </v-flex>

      <v-flex
        v-show="personalInfo['TOTAL CHOLESTEROL']"
        xs6
        md3
      >
        <v-text-field
          v-model="personalInfo['HDL RATIO']"
          label="HDL RATIO"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        v-show="personalInfo['TOTAL CHOLESTEROL']"
        xs6
        md3
      >
        <v-text-field
          v-model="personalInfo['LDL']"
          label="LDL"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        v-show="personalInfo['TOTAL CHOLESTEROL']"
        xs6
        md3
      >
        <v-text-field
          v-model="personalInfo['HDL']"
          label="HDL"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        v-show="personalInfo['TOTAL CHOLESTEROL']"
        xs6
        md3
      >
        <v-text-field
          v-model="personalInfo['TG']"
          label="TG"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/index'

// import { rules } from '../../../util/';
// import Radiolist from '@common/Radiolist/Radiolist';
import Radiolist from '@/components/common/Radiolist/Radiolist'

const STAGEOFREADINESSFORCHANGE = [
  'Patient eager to improve condition',
  'Patient condition currently controlled and hopes to maintain control',
  'Patient unsure',
  'Patient unwilling change',
]

const SUMMARYPATIENTGOALS = [
  'Improve compliance',
  'Avoid complications & side effects ',
  'Improve quality of life',
  'Control glucose levels',
  'Control blood pressure',
  'Control cholesterol levels',
  'Improve fitness',
  'Lose weight',
  'Improve diet',
  'Maintain conditions',
]

export default {
  components: {
    // 'radio-list': Radiolist
  },
  props: ['patientProfile'],
  data() {
    return {
      personalInfo: this.patientProfile,
      SUMMARYPATIENTGOALS,
      STAGEOFREADINESSFORCHANGE,
      rules: {
        ...rules,
      },
    }
  },
}
</script>

<style scoped>
</style>
