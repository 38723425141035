<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-checkbox
          v-model="enableCaregiver"
          color="primary"
          value="Yes"
          label="There is care giver"
        />
      </v-flex>

      <template v-if="enableCaregiver">
        <v-flex
          xs12
          md6
        >
          <v-text-field
            v-model="personalInfo['CAREGIVER FIRST NAME']"
            label="Caregiver first name"
            :rules="[rules.required]"
            type="text"
          >
          </v-text-field>
        </v-flex>
        <v-flex
          xs12
          md6
        >
          <v-text-field
            v-model="personalInfo['CAREGIVER LAST NAME']"
            label="Caregiver last name"
            :rules="[rules.required]"
            type="text"
          >
          </v-text-field>
        </v-flex>

        <v-flex
          xs12
          md6
        >
          <v-text-field
            v-model="personalInfo['CAREGIVER PHONE #']"
            label="Caregiver phone number"
            mask="phone"
            :rules="[rules.required]"
            type="phone"
          >
          </v-text-field>
        </v-flex>

        <v-flex
          xs12
          md6
        >
          <v-text-field
            v-model="personalInfo['CAREGIVER EMAIL ADDRESS']"
            label="Caregiver email address"
            type="email"
          >
          </v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-text-field
            v-model="personalInfo['CAREGIVER NOTES']"
            label="Caregiver notes"
            type="text"
          >
          </v-text-field>
        </v-flex>
      </template>
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/index'

export default {
  props: ['patientProfile'],
  data() {
    return {
      personalInfo: this.patientProfile,
      enableCaregiver: false,
      rules: {
        ...rules,
      },
    }
  },
}
</script>

<style scoped>
</style>
