<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <check-list
        :state="personalInfo"
        :checklist="checklistSources"
      ></check-list>
      <!-- <v-flex xs12>
                <v-text-field v-model="personalInfo['SOURCES OTHER NOTES']" label="Other sources consulted (details)" :rules="[rules.required]">
                </v-text-field>
            </v-flex> -->
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/index'
import Checklist from '@/components/common/Checklist/Checklist.vue'

const CHECKLIST_SOURCES_COLLECTION = [
  {
    name: 'PATIENT WILL GO FOR BLOOD WORK EVERY 3-6 MONTHS',
    label: 'PATIENT WILL GO FOR BLOOD WORK EVERY 3-6 MONTHS',
    value: 'PATIENT WILL GO FOR BLOOD WORK EVERY 3-6 MONTHS',
  },
  {
    name: 'PATIENT WILL CONTINUE WITH CURRENT REGIMEN',
    label: 'PATIENT WILL CONTINUE WITH CURRENT REGIMEN',
    value: 'PATIENT WILL CONTINUE WITH CURRENT REGIMEN',
  },
  {
    name: 'PATIENT WILL REVIEW MEDICATION REGIMEN WITH MD PERIODICALLY TO ENSURE EFFICACY AND REACHING TARGET LEVELS',
    label:
      'PATIENT WILL REVIEW MEDICATION REGIMEN WITH MD PERIODICALLY TO ENSURE EFFICACY AND REACHING TARGET LEVELS',
    value:
      'PATIENT WILL REVIEW MEDICATION REGIMEN WITH MD PERIODICALLY TO ENSURE EFFICACY AND REACHING TARGET LEVELS',
  },
  {
    name: 'PATIENT WILL INCREASE ACTIVITY AND WILL BE WATCHING DIENT REGIMEN',
    label: 'PATIENT WILL INCREASE ACTIVITY AND WILL BE WATCHING DIENT REGIMEN',
    value: 'PATIENT WILL INCREASE ACTIVITY AND WILL BE WATCHING DIENT REGIMEN',
  },
  {
    name: 'PHARMACY WILL ARRANGE FOR MEDICATION DELIVERY ON REGULAR BASIS',
    label: 'PHARMACY WILL ARRANGE FOR MEDICATION DELIVERY ON REGULAR BASIS',
    value: 'PHARMACY WILL ARRANGE FOR MEDICATION DELIVERY ON REGULAR BASIS',
  },
  {
    name: 'PATIENT WAS PUT ON A BLISTER PACK TO ENHANCE COMPLIANCE',
    label: 'PATIENT WAS PUT ON A BLISTER PACK TO ENHANCE COMPLIANCE',
    value: 'PATIENT WAS PUT ON A BLISTER PACK TO ENHANCE COMPLIANCE',
  },
]

export default {
  components: {
    'check-list': Checklist,
  },
  props: ['patientProfile'],
  data() {
    return {
      checklistSources: CHECKLIST_SOURCES_COLLECTION,
      personalInfo: this.patientProfile,
      rules: {
        ...rules,
      },
    }
  },
}
</script>

<style scoped>
</style>
