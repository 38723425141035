<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <check-list
        :state="personalInfo"
        :checklist="checklistClinical"
      ></check-list>
      <v-flex xs12>
        <v-text-field
          v-model="personalInfo['CLINICAL NEED FOR SERVICE COMMENTS']"
          label="Need for service comments"
          :rules="[rules.required]"
        >
        </v-text-field>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/index'

// import Checklist from '@common/Checklist/Checklist';
import Checklist from '@/components/common/Checklist/Checklist.vue'

const CHECKLIST_CLINICAL_COLLECTION = [
  {
    name: 'NEW PATIENT TO PHARMACY',
    label: 'For new patient',
    value: 'Yes',
  },
  {
    name: 'ANNUAL REVIEW',
    label: 'For annual review',
    value: 'Yes',
  },
  {
    name: 'PATIENT REQUEST',
    label: 'At patient request',
    value: 'Yes',
  },
  {
    name: 'PHYSICAIN REFERRAL',
    label: 'At physician referal',
    value: 'Yes',
  },
  {
    name: 'MEDICATION CHANGES',
    label: 'Due to medication changes',
    value: 'Yes',
  },
]

export default {
  components: {
    'check-list': Checklist,
  },
  props: ['patientProfile'],
  data() {
    return {
      checklistClinical: CHECKLIST_CLINICAL_COLLECTION,
      personalInfo: this.patientProfile,
      rules: {
        ...rules,
      },
    }
  },
}
</script>

<style scoped>
</style>
