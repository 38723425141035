<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <check-list
        :state="personalInfo"
        :checklist="checklistSources"
      ></check-list>
      <v-flex xs12>
        <v-text-field
          v-model="personalInfo['SOURCES OTHER NOTES']"
          label="Other sources consulted (details)"
          :rules="[rules.required]"
        >
        </v-text-field>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/index'
import Checklist from '@/components/common/Checklist/Checklist.vue'

const CHECKLIST_SOURCES_COLLECTION = [
  {
    name: 'CONSULTED PHARMACY PROFILE',
    label: 'Pharmacy profile',
    value: 'Yes',
    checked: true,
  },
  {
    name: 'CONSULTED PHYSICIAN / NURSE PRACTITIONER',
    label: 'Physician / nurse practitioner',
    value: 'Yes',
  },
  {
    name: 'CONSULTED PATIENT',
    label: 'Patient',
    value: 'Yes',
    checked: true,
  },
  {
    name: 'CONSULTED CAREGIVER / AGENT',
    label: 'Caregiver / agent',
    value: 'Yes',
  },
  {
    name: 'CONSULTED ANOTHER PHARMACY',
    label: 'Another pharmacy',
    value: 'Yes',
  },
  {
    name: 'CONSULTED MEDICATION PACKAGES',
    label: 'Medication packages',
    value: 'Yes',
  },
  {
    name: 'CONSULTED LABORATORY TEST VALUES',
    label: 'Laboratory test values',
    value: 'Yes',
  },
  {
    name: 'CONSULTED ELECTRONIC HEALTH RECROD',
    label: 'Electronic health record',
    value: 'Yes',
  },
  {
    name: 'CONSULTED HOSPITAL / OTHER FACILITY',
    label: 'Hospital / other facility',
    value: 'Yes',
  },
  {
    name: 'CONSULTED OTHER',
    label: 'Other',
    value: 'Yes',
  },
]

export default {
  components: {
    'check-list': Checklist,
  },
  props: ['patientProfile'],
  data() {
    return {
      checklistSources: CHECKLIST_SOURCES_COLLECTION,
      personalInfo: this.patientProfile,
      rules: {
        ...rules,
      },
    }
  },
}
</script>

<style scoped>
</style>
