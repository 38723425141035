<template>
    <v-container grid-list-md text-xs-left>
        <v-layout row wrap class="pg-input-radio" :class="{'pg-input-radio--dirty': valueSelected}">
            <!-- <v-flex v-for="(checkInstance, index) in radiolistCollection " :key="index" md6 xs12 @click="check(checkInstance)"> -->
            <v-flex v-for="(checkInstance, index) in radiolistCollection " :key="index" @click="check($event, checkInstance)">
                <v-checkbox :class="{'input-group--dirty': valueSelected}" @change="$emit('change', checkInstance.value, checkInstance)" color="primary" :true-value="checkInstance.value" false-value="" :label="checkInstance.label" :name="checkInstance.name" v-model="vm[checkInstance.name]" hide-details></v-checkbox>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        props: [
            'list',
            'state'
        ],
        data(){
            return {
                radiolistCollection: this.list,
                vm: this.state,
                valueSelected: false
            }
        },
        created(){
            // auto check
            this.radiolistCollection.map( (inst) => {
                if(inst.checked){
                    this.vm[inst.name] = inst.value;
                }
            });

            this.valueSelected = true;
            // update
            this.$forceUpdate();
        },
        methods: {
            check(e, choiceRef) {
                this.radiolistCollection.map( (record) => {
                    if(choiceRef.name !== record.name){
                        this.vm[record.name] = '';
                    }else{
                        this.vm[record.name] = record.value;
                    }
                });

                this.valueSelected = true;
                // force re-rendering
                // this.$nextTick( () => {                
                this.$forceUpdate();
                // });

                this.$emit('click', e);
            }
        }
    }
</script>

<style scoped>

</style>