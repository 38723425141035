<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-combobox
          v-model="personalInfo['SUMMARY OF DISCUSSION']"
          chips
          multiple
          :items="SUMMARYOFDISCUSSION1"
          label="Summary of discussion"
        ></v-combobox>
      </v-flex>

      <v-flex xs12>
        <v-combobox
          v-model="personalInfo['WHAT I WILL DO TO GET THERE']"
          multiple
          chips
          :items="WHATIWILLDOTOGETTHERE26"
          label="What i will do to get there"
        ></v-combobox>
      </v-flex>

      <v-flex xs12>
        <v-text-field
          v-model="personalInfo['WHAT I WILL DO TO GET THERE NOTES']"
          label="what i will do to get there notes"
          type="text"
        >
        </v-text-field>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/index'

const SUMMARYOFDISCUSSION1 = [
  'Assessed side effects and interactions',
  'Discussed adherence and compliance',
  'Initiate blister packs to improve compliance',
  'Reviewed all medications',
  'Reviewed blister packs to ensure compliance',
  'Updated medication record ',
]

const WHATIWILLDOTOGETTHERE26 = [
  'Adhere to medication regimen',
  'Compliance packaging',
  'Contact Physicians office',
  'Delivery of medications',
  'Reviewed blister packs to ensure compliance',
  'Improve diet',
  'Increase activity',
  'Make referral to md',
  'Monitoring of conditions',
]

export default {
  props: ['patientProfile'],
  data() {
    return {
      personalInfo: this.patientProfile,
      SUMMARYOFDISCUSSION1,
      WHATIWILLDOTOGETTHERE26,
      rules: {
        ...rules,
      },
    }
  },
}
</script>

<style scoped>
</style>
