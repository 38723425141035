import PatientInfo from '@/components/common/PatientInfo/PatientInfo.vue'
import AllergiesList from './AllergiesList.vue'
import CaregiverInfo from './CaregiverInfo.vue'
import CarePlanChoices from './CarePlanChoices.vue'
import ClinicalNeedForService from './ClinicalNeedForService.vue'
import CompletenessChecklist from './CompletenessChecklist.vue'
import CriteriaInfo from './CriteriaInfo.vue'
import DiabetesQuestionaire from './DiabetesQuestionaire/index'
import DiscontinuedMeds from './DiscontinuedMeds.vue'
import FrequentDispensing from './FrequentDispensing.vue'
import HealthcareProvider from './HealthcareProvider.vue'
import LifestyleInfo from './LifestyleInfo.vue'
import MedicationInfo from './MedicationInfo.vue'
import MedscheckType from './MedscheckType.vue'
import PatientAddress from './PatientAddress.vue'
import PatientCharacteristics from './PatientCharacteristics.vue'
import PharmacistInfo from './PharmacistInfo.vue'
import PharmacyInfo from './PharmacyInfo.vue'
import PrimaryProviderInfo from './PrimaryProviderInfo.vue'
import SourcesConsulted from './SourcesConsulted.vue'
import SummaryRegular from './SummaryRegular.vue'
import TherapeuticAdditionalComments from './TherapeuticAdditionalComments.vue'
import TherapeuticIssues from './TherapeuticIssues.vue'

// import DiabetesQuestionaie from './DiabetesQuestionaire/index';

export default {
  'allergies-list': AllergiesList,
  'care-plan': CarePlanChoices,
  'patient-info': PatientInfo,
  'pharmacy-info': PharmacyInfo,
  'pharmacist-info': PharmacistInfo,
  'caregiver-info': CaregiverInfo,
  'primary-provider-info': PrimaryProviderInfo,
  'clinical-need-for-service': ClinicalNeedForService,
  'patient-characteristics': PatientCharacteristics,
  'sources-consulted': SourcesConsulted,
  'medscheck-type': MedscheckType,
  'criteria-info': CriteriaInfo,
  'lifestyle-info': LifestyleInfo,
  'medication-info': MedicationInfo,
  'patient-address': PatientAddress,
  'therapeutic-issues': TherapeuticIssues,
  'therapeutic-additional-comments': TherapeuticAdditionalComments,
  'discontinued-meds': DiscontinuedMeds,
  'patient-summary': SummaryRegular,
  'completeness-checklist': CompletenessChecklist,
  'healthcare-provider': HealthcareProvider,
  'frequent-dispensing': FrequentDispensing,
  ...DiabetesQuestionaire,
}
