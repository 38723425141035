<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-subheader class="title black--text text-sm-left">
          Insulin use
        </v-subheader>
        <radio-list
          :state="personalInfo"
          :list="INSULIN_LIST"
        ></radio-list>
        <!-- @click="toggleInsulinUse()"; -->
      </v-flex>
    </v-layout>

    <v-layout
      v-if="personalInfo['INSULIN USE YES']"
      row
      wrap
    >
      <v-flex xs12>
        <v-select
          v-model="personalInfo['INSULIN TYPE 1']"
          chips
          multiple
          :items="INSULINTYPES_SELECT"
          label="Insulin type according to patient"
        ></v-select>
      </v-flex>

      <v-flex xs12>
        <v-checkbox
          v-model="personalInfo['UPDATED INSULIN REGIMEN']"
          color="primary"
          label="Updated insulin regimen"
          value="Yes"
          hide-details
        ></v-checkbox>
      </v-flex>

      <template v-if="personalInfo['UPDATED INSULIN REGIMEN']">
        <v-flex xs12>
          <v-subheader class="title black--text text-sm-left">
            INSULIN TYPE UPDATED 1
          </v-subheader>
          <v-select
            v-model="personalInfo['INSULIN TYPE UPDATED 1']"
            autocomplete
            auto
            chips
            tags
            :items="INSULINTYPEUPDATED1"
            label="INSULIN TYPE UPDATED 1"
          ></v-select>
        </v-flex>

        <v-flex
          xs12
          md2
          offset-md1
        >
          <v-text-field
            v-model="personalInfo['INSULIN UNITS AM 1']"
            label="UNITS AM"
            :rules="[rules.required]"
          ></v-text-field>
        </v-flex>

        <v-flex
          xs12
          md2
        >
          <v-text-field
            v-model="personalInfo['INSULIN UNITS NOON 1']"
            label="UNITS NOON"
            :rules="[rules.required]"
          ></v-text-field>
        </v-flex>

        <v-flex
          xs12
          md2
        >
          <v-text-field
            v-model="personalInfo['INSULIN UNITS SUPPER 1']"
            label="UNITS SUPPER"
            :rules="[rules.required]"
          ></v-text-field>
        </v-flex>

        <v-flex
          xs12
          md2
        >
          <v-text-field
            v-model="personalInfo['INSULIN UNITS HS 1']"
            label="UNITS HS"
            :rules="[rules.required]"
          ></v-text-field>
        </v-flex>

        <v-flex
          xs12
          md2
        >
          <v-text-field
            v-model="personalInfo['INSULIN UNITS OTHER 1']"
            label="UNITS OTHER"
            :rules="[rules.required]"
          ></v-text-field>
        </v-flex>

        <v-flex xs12>
          <v-subheader class="title black--text text-sm-left">
            INSULIN TYPE UPDATED 2
          </v-subheader>
          <v-select
            v-model="personalInfo['INSULIN TYPE UPDATED 2']"
            autocomplete
            auto
            chips
            tags
            :items="INSULINTYPEUPDATED2"
            label="INSULIN TYPE UPDATED 2"
          ></v-select>
        </v-flex>

        <v-flex
          xs12
          md2
          offset-md1
        >
          <v-text-field
            v-model="personalInfo['INSULIN UNITS AM 2']"
            label="UNITS AM"
            :rules="[rules.required]"
          ></v-text-field>
        </v-flex>

        <v-flex
          xs12
          md2
        >
          <v-text-field
            v-model="personalInfo['INSULIN UNITS NOON 2']"
            label="UNITS NOON"
            :rules="[rules.required]"
          ></v-text-field>
        </v-flex>

        <v-flex
          xs12
          md2
        >
          <v-text-field
            v-model="personalInfo['INSULIN UNITS SUPPER 2']"
            label="UNITS SUPPER"
            :rules="[rules.required]"
          ></v-text-field>
        </v-flex>

        <v-flex
          xs12
          md2
        >
          <v-text-field
            v-model="personalInfo['INSULIN UNITS HS 2']"
            label="UNITS HS"
            :rules="[rules.required]"
          ></v-text-field>
        </v-flex>

        <v-flex
          xs12
          md2
        >
          <v-text-field
            v-model="personalInfo['INSULIN UNITS OTHER 2']"
            label="UNITS OTHER"
            :rules="[rules.required]"
          ></v-text-field>
        </v-flex>
      </template>

      <v-expansion-panels multiple>
        <v-expansion-panel>
          <v-expansion-panel-header>
            Pen/Syringe Handling
          </v-expansion-panel-header>
          <v-expansion-panel-content :value="personalInfo['PEN HANDLING']">
            <!-- <div slot="header" class="title black--text text-sm-left">
            Pen/syringe handling
            <v-icon color="primary" class="my-0 mx-2" v-show="personalInfo['PEN HANDLING']">check</v-icon>
          </div> -->
            <!-- <v-card>
            <v-card-text> -->
            <v-checkbox
              v-model="personalInfo['TRAINING PEN HANDLING']"
              color="primary"
              label="Training for pen handling"
              value="Yes"
              hide-details
              @change="personalInfo['PEN HANDLING'] = 'Yes'"
            ></v-checkbox>
            <!-- </v-card-text>
          </v-card> -->
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Site preparation and inspection</v-expansion-panel-header>
          <v-expansion-panel-content :value="personalInfo['SITE PREPARTAION AND INSPECTION']">
            <v-checkbox
              v-model="personalInfo['TRAINING SITE PREPARATION']"
              color="primary"
              label="Training site preparation"
              value="Yes"
              hide-details
              @change="personalInfo['SITE PREPARTAION AND INSPECTION'] = 'Yes'"
            ></v-checkbox>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Injection Training</v-expansion-panel-header>
          <v-expansion-panel-content :value="personalInfo['INJECTION TRAINING']">
            <v-checkbox
              v-model="personalInfo['TRAINING GLP-1']"
              color="primary"
              label="Training GLP-1"
              value="Yes"
              hide-details
              @change="personalInfo['INJECTION TRAINING'] = 'Yes'"
            ></v-checkbox>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Proper Injection Technique</v-expansion-panel-header>
          <v-expansion-panel-content :value="personalInfo['PROPER INJECTION TECHNIQUE']">
            <v-checkbox
              v-model="personalInfo['TRAINING SITE ROTATION TECHNIQUE']"
              color="primary"
              label="Training site rotation technique"
              value="Yes"
              hide-details
              @change="personalInfo['PROPER INJECTION TECHNIQUE'] = 'Yes'"
            ></v-checkbox>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Patient Demostrated Use</v-expansion-panel-header>
          <v-expansion-panel-content :value="personalInfo['PATIENT DEMONSTRATED USE']">
            <radio-list
              :state="personalInfo"
              :list="PATIENTDEMONSTRATEDUSE"
            ></radio-list>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Dosage Adjustments</v-expansion-panel-header>
          <v-expansion-panel-content :value="personalInfo['DOSAGE ADJUSTMENTS']">
            <radio-list
              :state="personalInfo"
              :list="DOSAGEADJUSTMENTS"
            ></radio-list>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Missed or Skipped Doses</v-expansion-panel-header>
          <v-expansion-panel-content :value="personalInfo['MISSED OR SKIPPED DOSES']">
            <v-checkbox
              v-model="personalInfo['TRAINING MISSED OR SKIPPED INJECTIONS']"
              color="primary"
              label="Training missed or skipped injections"
              value="Yes"
              hide-details
              @change="personalInfo['MISSED OR SKIPPED DOSES'] = 'Yes'"
            ></v-checkbox>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Review of Carbohydrate Counting</v-expansion-panel-header>
          <v-expansion-panel-content :value="personalInfo['REVIEW OF CARBOHYDRATE COUNTING']">
            <v-checkbox
              v-model="personalInfo['TRAINING CARB COUNTING']"
              color="primary"
              label="Training carbohydrate counting"
              value="Yes"
              hide-details
              @change="personalInfo['REVIEW OF CARBOHYDRATE COUNTING'] = 'Yes'"
            ></v-checkbox>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Emergency and Sick Day Management</v-expansion-panel-header>
          <v-expansion-panel-content :value="personalInfo['EMERGENCY AND SICK DAY MANAGMENT']">
            <radio-list
              :state="personalInfo"
              :list="TRAININGEMERGENCYANDSICKDAY"
            ></radio-list>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Alcohol/ Drugs/ Tobacco</v-expansion-panel-header>
          <v-expansion-panel-content :value="personalInfo['ALCOHOL/DRUGS/TOBACO']">
            <radio-list
              :state="personalInfo"
              :list="TRAININGALOCHOLREVIWED"
            ></radio-list>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Specialty Training Other</v-expansion-panel-header>
          <v-expansion-panel-content :value="personalInfo['SPECIALTY TRAINING OTHER']">
            <v-text-field
              v-model="personalInfo['SPECIALTY TRAINING OTHER NOTE']"
              label="SPECIALTY TRAINING OTHER NOTE"
              :rules="[rules.required]"
              @input="isCheckedText('SPECIALTY TRAINING OTHER','SPECIALTY TRAINING OTHER NOTE')"
            ></v-text-field>
          </v-expansion-panel-content>
        </v-expansion-panel>

        <v-expansion-panel>
          <v-expansion-panel-header>Other Complications Discussed</v-expansion-panel-header>
          <v-expansion-panel-content :value="personalInfo['OTHER COMPLICATIONS DISCUSSED']">
            <v-text-field
              v-model="personalInfo['OTHER COMPLICATIONS DISCUSSED NOTES']"
              label="OTHER COMPLICATIONS DISCUSSED NOTES"
              :rules="[rules.required]"
              @input="isCheckedText('OTHER COMPLICATIONS DISCUSSED','OTHER COMPLICATIONS DISCUSSED NOTES')"
            ></v-text-field>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>

      <!-- <v-flex xs12>
                <v-subheader class="title black--text text-sm-left">
                    Emergency and sick day managment
                </v-subheader>
                <check-list :state="personalInfo"
                    :checklist="EMERGENCYANDSICKDAYMANAGMENT"
                ></check-list>
                <check-list :state="personalInfo"
                    :checklist="TRAININGEMERGENCYANDSICKDAY"
                ></check-list>
      </v-flex>-->

      <!-- <v-flex xs12>
                <v-subheader class="title black--text text-sm-left">
                    Alcohol / Drugs / Tabaco
                </v-subheader>
                <check-list :state="personalInfo"
                    :checklist="ALCOHOLDRUGSTOBACO"
                ></check-list>
                <check-list :state="personalInfo"
                    :checklist="TRAININGALOCHOLREVIWED"
                ></check-list>

      </v-flex>-->

      <!-- <v-flex xs12>
                <v-subheader class="title black--text text-sm-left">
                    Specialty training other
                </v-subheader>
                <check-list :state="personalInfo"
                    :checklist="SPECIALTYTRAININGOTHER"
                ></check-list>
            </v-flex>

            <v-flex xs12>
                <v-text-field v-model="personalInfo['SPECIALTY TRAINING OTHER NOTE']" label="SPECIALTY TRAINING OTHER NOTE" :rules="[rules.required]">
                </v-text-field>
            </v-flex>

            <v-flex xs12>
                <v-subheader class="title black--text text-sm-left">
                    Other complications discussed
                </v-subheader>
                <check-list :state="personalInfo"
                    :checklist="OTHERCOMPLICATIONSDISCUSSED"
                ></check-list>
            </v-flex>

            <v-flex xs12>
                <v-text-field v-model="personalInfo['OTHER COMPLICATIONS DISCUSSED NOTES']" label="OTHER COMPLICATIONS DISCUSSED NOTES" :rules="[rules.required]">
                </v-text-field>
      </v-flex>-->
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/index'

// import Checklist from "../../../../components/common/Checklist/Checklist.vue";
// import Radiolist from "@common/Radiolist/Radiolist";
// import Radiolist from "../../../../components/common/Radiolist/Radiolist";
import Radiolist from '@/components/common/Radiolist/Radiolist'

const INSULINTYPES_SELECT = [
  'LONG-ACTING',
  'INTERMEDIATE',
  'SHORT-ACTING',
  'RAPID-ACTING',
  'NONE',
]

const INSULINTYPEUPDATED1 = [
  'LONG-ACTING',
  'INTERMEDIATE',
  'SHORT-ACTING',
  'RAPID-ACTING',
]

const INSULINTYPEUPDATED2 = [
  'LONG-ACTING',
  'INTERMEDIATE',
  'SHORT-ACTING',
  'RAPID-ACTING',
]

//    const PENHANDLING = [
//         {
//             name: "PEN HANDLING",
//             label: "PEN HANDLING",
//             value: "Yes"

//         }
//     ];

//    const TRAININGPENHANDLING = [
//         {
//             name: "TRAINING PEN HANDLING",
//             label: "TRAINING PEN HANDLING",
//             value: "Yes"

//         }
//     ];

//    const SITEPREPARTAIONANDINSPECTION = [
//         {
//             name: "SITE PREPARTAION AND INSPECTION",
//             label: "SITE PREPARTAION AND INSPECTION",
//             value: "Yes"

//         }
//     ];

//    const TRAININGSITEPREPARATION = [
//         {
//             name: "TRAINING SITE PREPARATION",
//             label: "TRAINING SITE PREPARATION",
//             value: "Yes"

//         }
//     ];

//    const INJECTIONTRAINING = [
//         {
//             name: "INJECTION TRAINING",
//             label: "INJECTION TRAINING",
//             value: "Yes"

//         }
//     ];

//    const TRAININGGLP1 = [
//         {
//             name: "TRAINING GLP-1",
//             label: "TRAINING GLP-1",
//             value: "Yes"

//         }
//     ];

//    const PROPERINJECTIONTECHNIQUE = [
//         {
//             name: "PROPER INJECTION TECHNIQUE",
//             label: "PROPER INJECTION TECHNIQUE",
//             value: "Yes"

//         }
//     ];

//    const TRAININGSITEROTATIONTECHNIQUE = [
//         {
//             name: "TRAINING SITE ROTATION TECHNIQUE",
//             label: "TRAINING SITE ROTATION TECHNIQUE",
//             value: "Yes"

//         }
//     ];

const PATIENTDEMONSTRATEDUSE = [
  {
    name: 'TRAINING DEMOSTRATED USE YES',
    label: 'Yes',
    value: 'Yes',
  },
  {
    name: 'TRAINING DEMONSTRATED USE NO',
    label: 'No',
    value: 'Yes',
  },
]

const DOSAGEADJUSTMENTS = [
  {
    name: 'TRAINING DOSAGE ADJUMENTS NOT RQUIRED',
    label: 'Not required',
    value: 'Yes',
  },
  {
    name: 'TRAINING DOSAGE ADJ PHYSICAN',
    label: 'Training dosage adjustment physican',
    value: 'Yes',
  },
]

//    const TRAININGMISSEDORSKIPPEDINJECTIONS = [
//         {
//             name: "TRAINING MISSED OR SKIPPED INJECTIONS",
//             label: "TRAINING MISSED OR SKIPPED INJECTIONS",
//             value: "Yes"
//         }
//     ];

//    const REVIEWOFCARBOHYDRATECOUNTING = [
//         {
//             name: "REVIEW OF CARBOHYDRATE COUNTING",
//             label: "REVIEW OF CARBOHYDRATE COUNTING",
//             value: "Yes"
//         }
//     ];

//    const EMERGENCYANDSICKDAYMANAGMENT = [
//         {
//             name: "EMERGENCY AND SICK DAY MANAGMENT",
//             label: "EMERGENCY AND SICK DAY MANAGMENT",
//             value: "Yes"
//         }
//     ];

const TRAININGEMERGENCYANDSICKDAY = [
  {
    name: 'TRAINING EMERGENCY AND SICK DAY',
    label: 'Training emergency and sick day',
    value: 'Yes',
  },
  {
    name: 'TRAINING EMERGENCY NA',
    label: 'Not required',
    value: 'Yes',
  },
]

//    const ALCOHOLDRUGSTOBACO = [
//         {
//             name: "ALCOHOL/DRUGS/TOBACO",
//             label: "ALCOHOL/DRUGS/TOBACO",
//             value: "Yes"
//         }
//     ];

const TRAININGALOCHOLREVIWED = [
  {
    name: 'TRAINING ALOCHOL REVIWED',
    label: 'Training Alcohol Reviewed',
    value: 'Yes',
  },
  {
    name: 'ALCHOL DRUGS NA',
    label: 'Not Required',
    value: 'Yes',
  },
]

//    const SPECIALTYTRAININGOTHER = [
//         {
//             name: "SPECIALTY TRAINING OTHER",
//             label: "SPECIALTY TRAINING OTHER",
//             value: "Yes"

//         }
//     ];

//    const OTHERCOMPLICATIONSDISCUSSED = [
//         {
//             name: "OTHER COMPLICATIONS DISCUSSED",
//             label: "OTHER COMPLICATIONS DISCUSSED",
//             value: "Yes"

//         }
//     ];

const INSULIN_LIST = [
  {
    name: 'INSULIN USE YES',
    label: 'Yes',
    value: 'Yes',
  },
  {
    name: 'INSULIN USE NO',
    label: 'No',
    value: 'Yes',
  },
]

export default {
  components: {
    // "check-list": Checklist,
    'radio-list': Radiolist,
  },
  props: ['patientProfile'],
  data() {
    return {
      personalInfo: this.patientProfile,
      INSULINTYPEUPDATED1,
      INSULINTYPEUPDATED2,

      // PENHANDLING,
      // TRAININGPENHANDLING,
      // SITEPREPARTAIONANDINSPECTION,
      // TRAININGSITEPREPARATION,
      // INJECTIONTRAINING,
      // TRAININGGLP1,
      // PROPERINJECTIONTECHNIQUE,
      // TRAININGSITEROTATIONTECHNIQUE,
      PATIENTDEMONSTRATEDUSE,
      DOSAGEADJUSTMENTS,

      // MISSEDORSKIPPEDDOSES,
      // TRAININGMISSEDORSKIPPEDINJECTIONS,
      // REVIEWOFCARBOHYDRATECOUNTING,
      // EMERGENCYANDSICKDAYMANAGMENT,
      TRAININGEMERGENCYANDSICKDAY,

      // ALCOHOLDRUGSTOBACO,
      TRAININGALOCHOLREVIWED,

      // SPECIALTYTRAININGOTHER,
      // OTHERCOMPLICATIONSDISCUSSED,
      INSULINTYPES_SELECT,
      INSULIN_LIST,
      rules: {
        ...rules,
      },
    }
  },
  methods: {
    // toggleInsulinUse() {
    //     if(!!this.personalInfo['INSULIN USE YES']){
    //         this.personalInfo['INSULIN TYPES'] = 'Yes';
    //     }else{
    //         this.personalInfo['INSULIN TYPES'] = '';
    //     }
    // }
    checked(key, list) {
      this.personalInfo[key] = null
      const isChecked = list.findIndex(ref => this.personalInfo[ref.name]) != -1

      if (isChecked) {
        this.personalInfo[key] = 'Yes'
      }

      this.$forceUpdate()
    },
    isCheckedText(checkboxKey, textKey) {
      this.personalInfo[checkboxKey] = this.personalInfo[textKey].length
        ? 'Yes'
        : null
    },
  },
}
</script>

<style scoped>
</style>
