<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-textarea
          id="v-step-14"
          v-model="personalInfo['ADDITIONAL MEDICATION COMMENTS']"
          multi-line
          label="Drug interactions being monitored and additional medication comments:"
          type="text"
        >
        </v-textarea>
      </v-flex>
      <!-- <v-text-field  v-model="personalInfo['PATIENT WILL CONTINUE WITH CURRENT REGIMEN']" label="Clinically relevant discontinued medications" multi-line>
 </v-text-field> -->
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/index'

export default {
  props: ['patientProfile'],
  data() {
    return {
      personalInfo: this.patientProfile,

      rules: {
        ...rules,
      },
    }
  },
  updated() {
    // this.personalInfo["ADDITIONAL MEDICATION COMMENTS"]
  },
}
</script>

<style scoped>
</style>
