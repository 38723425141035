<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <strong>Asked about:</strong>
      <check-list
        :state="personalInfo"
        :checklist="ASKEDABOUTRXMEDICATIONS"
      ></check-list>

      <v-divider
        inset
        class="my-5"
      ></v-divider>
      <check-list
        id="v-step-16"
        :state="personalInfo"
        :checklist="ASKEDABOUTPRODUCTS"
      >
      </check-list>

      <v-flex
        v-show="personalInfo['MEDS REMOVED FROM HOME']"
        xs12
      >
        <v-text-field
          v-model="personalInfo['REMOVED FROM HOME']"
          label="Removed from home"
          :rules="[rules.required]"
          multi-line
        >
        </v-text-field>
      </v-flex>

      <check-list
        :state="personalInfo"
        :checklist="CIRCLEOFCARE"
      ></check-list>
      <div></div>

      <check-list
        :state="personalInfo"
        :checklist="CLINICALLYRELVANTINFORMATIONDOCUMNETED"
      ></check-list>

      <v-flex
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['COMPLETENESS OTHER NOTES']"
          label="Completeness other notes"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/index'
import Checklist from '@/components/common/Checklist/Checklist.vue'

const ASKEDABOUTRXMEDICATIONS = [
  {
    name: 'ASKED ABOUT RX MEDICATIONS',
    label: 'RX meds',
    value: 'Yes',
    checked: true,
  },
  {
    name: 'ASKED ABOUT OTC PRODUCTS',
    label: 'OTC products',
    value: 'Yes',
    checked: true,
  },
  {
    name: 'ASKED ABOUT HERBAL PRODUCTS',
    label: 'Herbal products',
    value: 'Yes',
    checked: true,
  },
  {
    name: 'ASKED ABOUT UNCOMMON DOSAGE FORMS',
    label: 'Uncommon dosage forms',
    value: 'Yes',
    checked: true,
  },
  {
    name: 'ASKED ABOUT ANTIOBITOCS IN THE LAST 3 MONTHS',
    label: 'Antibiotics in the last 3 months',
    value: 'Yes',
    checked: true,
  },
]

const ASKEDABOUTPRODUCTS = [
  {
    name: 'MEDS REMOVED FROM HOME',
    label: 'Meds removed from home',
    value: 'Yes',
  },
]

const CIRCLEOFCARE = [
  {
    name: 'REFERECNED NOTES',
    label: 'Referenced notes',
    value: 'Yes',
    checked: true,
  },
  {
    name: 'CIRCLE OF CARE',
    label: 'Circle of care',
    value: 'Yes',
    checked: true,
  },
]

const CLINICALLYRELVANTINFORMATIONDOCUMNETED = [
  {
    name: 'ANTICIPATED DATE OF COMPLETION',
    label: 'Anticipated date of completion',
    value: 'Yes',
    checked: true,
  },
  {
    name: 'CLINICALLY RELVANT INFORMATION DOCUMNETED',
    label: 'Relevant information documented',
    value: 'Yes',
    checked: true,
  },
  {
    name: 'COMPLETENESS OTHER',
    label: 'Completeness other',
    value: 'Yes',
    checked: true,
  },
]

const NOFOLLOWUPISSUES = [
  {
    name: 'NO FOLLOW UP ISSUES',
    label: 'No follow up issues',
    value: 'Yes',
    checked: true,
  },
  {
    name: 'FOLLOW UP ISSUES',
    label: 'Follow up issues',
    value: 'Yes',
    checked: true,
  },
]

export default {
  components: {
    'check-list': Checklist,
  },
  props: ['patientProfile'],
  data() {
    return {
      personalInfo: this.patientProfile,
      ASKEDABOUTRXMEDICATIONS,
      ASKEDABOUTPRODUCTS,
      CIRCLEOFCARE,
      CLINICALLYRELVANTINFORMATIONDOCUMNETED,
      rules: {
        ...rules,
      },
    }
  },
}
</script>

<style scoped>
</style>
