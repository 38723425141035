<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        md6
        class="table"
      >
        <div id="v-step-18"></div>
        <v-subheader class="title black--text text-sm-left">
          Physical impairment
        </v-subheader>
        <v-flex
          v-for="(checkInstance, index) in PHYSICALIMPAIRMENT "
          :key="index"
          xs12
        >
          <v-checkbox
            v-model="personalInfo[checkInstance.name]"
            color="primary"
            :true-value="checkInstance.value"
            false-value=""
            :label="checkInstance.label"
            :name="checkInstance.name"
            :hide-details="!checkInstance.hint"
            :persistent-hint="!!checkInstance.hint"
            :hint="checkInstance.hint"
          ></v-checkbox>
        </v-flex>
      </v-flex>

      <v-flex
        xs12
        md6
        class="table"
      >
        <v-subheader class="title black--text text-sm-left">
          Cognitive impairment
        </v-subheader>
        <v-flex
          v-for="(checkInstance, index) in COGNITIVEIMPAIRMENT "
          :key="index"
          xs12
        >
          <v-checkbox
            v-model="personalInfo[checkInstance.name]"
            color="primary"
            :true-value="checkInstance.value"
            false-value=""
            :label="checkInstance.label"
            :name="checkInstance.name"
            :hide-details="!checkInstance.hint"
            :persistent-hint="!!checkInstance.hint"
            :hint="checkInstance.hint"
            wrap
          ></v-checkbox>
        </v-flex>
      </v-flex>

      <v-flex
        xs12
        md6
        class="table"
      >
        <v-subheader class="title black--text text-sm-left">
          Sensory impairment
        </v-subheader>
        <v-flex
          v-for="(checkInstance, index) in SENSORYIMPAIRMENT "
          :key="index"
          xs12
        >
          <v-checkbox
            v-model="personalInfo[checkInstance.name]"
            color="primary"
            :true-value="checkInstance.value"
            false-value=""
            :label="checkInstance.label"
            :name="checkInstance.name"
            :hide-details="!checkInstance.hint"
            :persistent-hint="!!checkInstance.hint"
            :hint="checkInstance.hint"
          ></v-checkbox>
        </v-flex>
      </v-flex>

      <v-flex
        xs12
        md6
        class="table"
      >
        <v-subheader class="title black--text text-sm-left">
          Complex medications
        </v-subheader>
        <v-flex
          v-for="(checkInstance, index) in COMPLEXMEDS "
          :key="index"
          xs12
        >
          <v-checkbox
            v-model="personalInfo[checkInstance.name]"
            color="primary"
            :true-value="checkInstance.value"
            false-value=""
            :label="checkInstance.label"
            :name="checkInstance.name"
            :hide-details="!checkInstance.hint"
            :persistent-hint="!!checkInstance.hint"
            :hint="checkInstance.hint"
          ></v-checkbox>
        </v-flex>
      </v-flex>

      <v-flex
        xs12
        md12
      >
        <v-subheader class="title black--text text-sm-left px-0">
          Dispensing frequency
        </v-subheader>
      </v-flex>

      <v-flex
        v-for="(checkInstance, index) in FREQUENTDISPENSING "
        :key="index"
        xs3
      >
        <v-checkbox
          v-model="personalInfo[checkInstance.name]"
          color="primary"
          :true-value="checkInstance.value"
          false-value=""
          :label="checkInstance.label"
          :name="checkInstance.name"
          hide-details
        ></v-checkbox>
      </v-flex>

      <v-flex
        v-show="personalInfo['DISPENSING REGMIN CHECKBOX DAYS OTHER']"
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['OTHER DIESPNSEING REGIMEN']"
          label="Other diespnseing regimen"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex xs12>
        <v-autocomplete
          v-model="personalInfo['RATIONALE FOR FREQUENT DISPENSING']"
          multiple
          chips
          :items="RATIONALEFORFREQUENTDISPENSING"
          label="Rationale for frequent dispensing"
        ></v-autocomplete>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/index'

// import Checklist from "@common/Checklist/Checklist";

const PHYSICALIMPAIRMENT = [
  {
    name: 'PHYSICAL IMPAIRMENT CHECKBOX 1',
    label: 'Physical functioning',
    value: 'Yes',
  },
  {
    name: 'PHYSICAL IMPAIRMENT CHECKBOX 2',
    label: 'Mobility',
    value: 'Yes',
  },
  {
    name: 'PHYSICAL IMPAIRMENT CHECKBOX 3',
    label: 'Dexterity',
    value: 'Yes',
  },
  {
    name: 'PHYSICAL IMPAIRMENT CHECKBOX 4',
    label: 'Stamina',
    value: 'Yes',
  },
  {
    name: 'PHYSICAL IMPAIRMENT CHECKBOX 5',
    label: 'Other',
    value: 'Yes',
    hint: 'Other Physical disablities include impairments which limit other facets of daily living such as: Respiratory disorders, blindness, epilepsy and sleep disorders.',
  },
]

const COGNITIVEIMPAIRMENT = [
  {
    name: 'COGNITIVE IMPAIRMENT CHECKBOX 1',
    label: 'Remembering',
    value: 'Yes',
  },
  {
    name: 'COGNITIVE IMPAIRMENT CHECKBOX 2',
    label: 'Learning new things',
    value: 'Yes',
  },
  {
    name: 'COGNITIVE IMPAIRMENT CHECKBOX 3',
    label: 'Concentrating',
    value: 'Yes',
  },
  {
    name: 'COGNITIVE IMPAIRMENT CHECKBOX 4',
    label: 'Making decisions that affect every day life',
    value: 'Yes',
  },
  {
    name: 'COGNITIVE IMPAIRMENT CHECKBOX 5',
    label: 'Drug-induced impairment',
    value: 'Yes',
  },
]

const SENSORYIMPAIRMENT = [
  {
    name: 'SENSORY IMPAIRMENT CHECKBOX 1',
    label: 'Sight',
    value: 'Yes',
  },
  {
    name: 'SENSORY IMPAIRMENT CHECKBOX 2',
    label: 'Hearing',
    value: 'Yes',
  },
  {
    name: 'SENSORY IMPAIRMENT CHECKBOX 3',
    label: 'Smell',
    value: 'Yes',
  },
  {
    name: 'SENSORY IMPAIRMENT CHECKBOX 4',
    label: 'Touch',
    value: 'Yes',
  },
  {
    name: 'SENSORY IMPAIRMENT CHECKBOX 5',
    label: 'Taste',
    value: 'Yes',
  },
  {
    name: 'SENSORY IMPAIRMENT CHECKBOX 6',
    label: 'Spatial awareness',
    value: 'Yes',
  },
]

const COMPLEXMEDS = [
  {
    name: 'COMPLEX MEDS CHECKBOX 1',
    label: '1-2 meds',
    value: 'Yes',
  },
  {
    name: 'COMPLEX MEDS CHECKBOX 2',
    label: '3-4 meds',
    value: 'Yes',
  },
  {
    name: 'COMPLEX MEDS CHECKBOX 3',
    label: '5-6 meds',
    value: 'Yes',
  },
  {
    name: 'COMPLEX MEDS CHECKBOX 4',
    label: '7+ meds',
    value: 'Yes',
  },
  {
    name: 'COMPLEX MEDS CHECKBOX 5',
    label: 'Meds that require cutting',
    value: 'Yes',
  },
  {
    name: 'COMPLEX MEDS CHECKBOX 6',
    label: 'Meds requiring multiple doses per day',
    value: 'Yes',
  },
  {
    name: 'COMPLEX MEDS CHECKBOX 7',
    label: 'Meds on specialized schedules',
    value: 'Yes',
  },
]

const FREQUENTDISPENSING = [
  {
    name: 'DISPENSING REGMIN CHECKBOX DAYS 7',
    label: 'Dispensing every 7 days',
    value: 'Yes',
  },
  {
    name: 'DISPENSING REGMIN CHECKBOX DAYS 14',
    label: 'Dispensing every 14 days',
    value: 'Yes',
  },
  {
    name: 'DISPENSING REGMIN CHECKBOX DAYS 28',
    label: 'Dispensing every 28 days',
    value: 'Yes',
  },
  {
    name: 'DISPENSING REGMIN CHECKBOX DAYS OTHER',
    label: 'Other',
    value: 'Yes',
  },
]

const RATIONALEFORFREQUENTDISPENSING = [
  'Medication regimen is complex and may forget to take medications or take multiple times throughout the day resulting in a DRP',
  'Frequent changes in medication. Patient unable to manage changes without risk of a DRP',
  'Patient at risk for adverse health outcomes',
  'Patient loses medications',
  'Patient has a hard time identifying medications which may result in a DRP',
  'Patient Finds compliance packaging to be safer and more convenient',
  'Patient is requesting blister packs to help with compliance.',
  'Patient needs close monitoring to ensure they are taking their medications.',
  'High potential for abuse of medications',
  'Patient requires assistance with medication regimen and vitamins and compliance',
  'Client of special housing program',
  'Unstable housing arrangement',
]

export default {
  components: {
    // "check-list": Checklist
  },
  props: ['patientProfile'],
  data() {
    return {
      personalInfo: this.patientProfile,
      PHYSICALIMPAIRMENT,
      COGNITIVEIMPAIRMENT,
      SENSORYIMPAIRMENT,
      COMPLEXMEDS,
      FREQUENTDISPENSING,
      RATIONALEFORFREQUENTDISPENSING,
      rules: {
        ...rules,
      },
    }
  },
}
</script>

<style scoped>
.input-group__details:contains('<!---->') {
  display: none;
}

.table {
  border: 1px solid black;
}

/* .table + .table{
        border-left: 0px none;
    } */

.table .subheader {
  border-bottom: 1px solid black;
}
</style>
