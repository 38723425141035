import GeneralInfo from './GeneralInfo';
// import GeneralInfo from './';
import Education from './Education';
import Monitoring from './Monitoring';
import Trainning from './Trainning';
import Resources from './Resources';
import Referrals from './Referrals';
import Summary from './Summary';

export default {
    'diabetes-general-info': GeneralInfo,
    'diabetes-education': Education,
    'diabetes-monitoring': Monitoring,
    'diabetes-trainning': Trainning,
    'diabetes-resources': Resources,
    'diabetes-referrals': Referrals,
    // 'diabetic-summary': Summary
}