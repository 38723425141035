<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        lg6
      >
        <v-checkbox
          v-model="personalInfo['CHECKBOX PATIENT NOT TAKING ANY NON-PRESCRIPTION PRODUCTS']"
          color="primary"
          value="Yes"
          label="Patient is not taking any non-prescription products"
        />
      </v-flex>

      <v-flex
        xs12
        lg6
      >
        <div id="v-step-13">
          <v-checkbox
            v-model="personalInfo['REQUIRES FREQUENT DISPENSING']"
            color="primary"
            value="Yes"
            label="Requires frequent dispensing"
          />
        </div>
      </v-flex>
      <v-flex xs12>
        <v-text-field
          v-model="personalInfo['CLINICALLY RELEVANT DISCONTINUED MEDICATIONS']"
          label="Clinically relevant discontinued medications"
          multi-line
        >
        </v-text-field>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/index'

export default {
  props: ['patientProfile'],
  data() {
    return {
      personalInfo: this.patientProfile,
      rules: {
        ...rules,
      },
    }
  },
}
</script>

<style scoped>
</style>
