<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <check-list
        :state="personalInfo"
        :checklist="checklistCriteria"
      ></check-list>
      <v-flex xs12>
        <v-text-field
          v-model="personalInfo['PATIENT CHARACTERISTICS OTHER NOTE']"
          label="Other concerns (details)"
          :rules="[rules.required]"
        >
        </v-text-field>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/index'
import Checklist from '@/components/common/Checklist/Checklist.vue'

const CHECKLIST_CRITIRIA_COLLECTION = [
  {
    name: 'THREE OR MORE MEDICATIONS',
    label: 'Takes 3 or more meds',
    value: 'Yes',
    checked: true,
  },
  {
    name: 'MULTIPLE CONDITIONS',
    label: 'Has multiple conditions',
    value: 'Yes',
    checked: false,
  },
  {
    name: 'ONE OR MORE NON RX MEDS',
    label: 'Takes non RX meds',
    value: 'Yes',
    checked: false,
  },
  {
    name: 'ONE OR MORE NHP',
    label: 'Takes natural health products',
    value: 'Yes',
    checked: false,
  },
  {
    name: 'UNDADDRESSED SYMPTOMS',
    label: 'Has unaddressed symptoms',
    value: 'Yes',
    checked: false,
  },
  {
    name: 'POTENTIAL DRPS',
    label: 'Potential drug therapy problems',
    value: 'Yes',
    checked: false,
  },
  {
    name: 'MULTIPLE DOCTORS',
    label: 'Has multiple prescribers',
    value: 'Yes',
    checked: false,
  },
  {
    name: 'ISSUES EARLY OR LATE REFILLS',
    label: 'Has compliance issues',
    value: 'Yes',
    checked: false,
  },
  {
    name: 'NON-ADHERENCE',
    label: 'Is non-adherent with regimen',
    value: 'Yes',
    checked: false,
  },
  {
    name: 'PATIENT CONFUSED',
    label: 'Is confused with regimen',
    value: 'Yes',
    checked: false,
  },
  {
    name: 'MEDS WITH LAB MONITORING',
    label: 'Requires regular lab monitoring',
    value: 'Yes',
    checked: false,
  },
  {
    name: 'ABNORMAL LAB RESULTS',
    label: 'Has abnormal lab results',
    value: 'Yes',
    checked: false,
  },
  {
    name: 'PLANNED ADMISSION',
    label: 'Has planned hospital admission',
    value: 'Yes',
    checked: false,
  },
  {
    name: 'DISCHARGE FROM HOSPITAL TO COMMUNITY',
    label: 'Recent hospital discharge',
    value: 'Yes',
    checked: false,
  },
  {
    name: 'COMPLIANCE PACKAGING',
    label: 'Initiating compliance packaging',
    value: 'Yes',
    checked: false,
  },
  {
    name: 'RENAL FUNCTION',
    label: 'Has renal issues',
    value: 'Yes',
    checked: false,
  },
  {
    name: 'LIVER FUNCTION',
    label: 'Has hepatic (liver) issues',
    value: 'Yes',
    checked: false,
  },
  {
    name: 'PATIENT CHARACTERISTICS OTHER',
    label: 'Has other concerns',
    value: 'Yes',
    checked: false,
  },
]

export default {
  components: {
    'check-list': Checklist,
  },
  props: ['patientProfile'],
  data() {
    return {
      checklistCriteria: CHECKLIST_CRITIRIA_COLLECTION,
      personalInfo: this.patientProfile,
      rules: {
        ...rules,
      },
    }
  },
}
</script>

<style scoped>
</style>
