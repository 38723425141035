<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        md12
      >
        <v-checkbox
          v-model="personalInfo['REFERRALS ENDOCRINOLOGIST']"
          color="primary"
          label="Endocrinologist"
          true-value="Yes"
          false-value=""
          hide-details
        ></v-checkbox>
      </v-flex>

      <v-flex
        v-show="personalInfo['REFERRALS ENDOCRINOLOGIST']"
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['REFERRAL ENDO']"
          label="Name of Endocrinologist"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md12
      >
        <v-checkbox
          v-model="personalInfo['REFERRALS PRIMARY CARE PHYSICIAN']"
          color="primary"
          label="Primary care physician"
          true-value="Yes"
          false-value=""
          hide-details
          @click.native="clickPrimaryCareToggle()"
        ></v-checkbox>
      </v-flex>

      <v-flex
        v-show="personalInfo['REFERRALS PRIMARY CARE PHYSICIAN']"
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['REFERRAL PRIMARY CARE']"
          label="Referral primary care"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md12
      >
        <v-checkbox
          v-model="personalInfo['REFERRALS DIETITIAN']"
          color="primary"
          label="Dietitian"
          true-value="Yes"
          false-value=""
          hide-details
        ></v-checkbox>
      </v-flex>

      <v-flex
        v-show="personalInfo['REFERRALS DIETITIAN']"
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['REFERRAL DIETITIAN']"
          label="Name of Dietitian"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md12
      >
        <v-checkbox
          v-model="personalInfo['REFERRALS NURSE']"
          color="primary"
          label="Nurse"
          true-value="Yes"
          false-value=""
          hide-details
        ></v-checkbox>
      </v-flex>

      <v-flex
        v-show="personalInfo['REFERRALS NURSE']"
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['REFERRAL NURSE']"
          label="Name of Nurse"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md12
      >
        <v-checkbox
          v-model="personalInfo['REFERRALS FAMILY PHARMACIST']"
          color="primary"
          label="Family pharmacist"
          true-value="Yes"
          false-value=""
          hide-details
        ></v-checkbox>
      </v-flex>

      <v-flex
        v-show="personalInfo['REFERRALS FAMILY PHARMACIST']"
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['REFERRAL PHARMACIST']"
          label="Name of Pharmacist"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md12
      >
        <v-checkbox
          v-model="personalInfo['REFERRALS OTHER']"
          color="primary"
          label="Other"
          true-value="Yes"
          false-value=""
          hide-details
        ></v-checkbox>
      </v-flex>

      <v-flex
        v-show="personalInfo['REFERRALS OTHER']"
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['REFERRAL  OTHER']"
          label="Name of other"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <!-- <v-flex xs12 md12>
                <v-menu
                    ref="dateInput"

                    transition="scale-transition"
                    offset-y

                    :nudge-right="40"
                    max-width="290px"
                    min-width="290px"
                >
                    <v-text-field slot="activator" v-model="personalInfo['FOLLOW UP DATE']" label="Diabetic Follow up date" prepend-icon="event" :rules="[rules.required]" readonly>
                    </v-text-field>
                    <v-date-picker v-model="personalInfo['FOLLOW UP DATE']" no-title scrollable>
                        <v-spacer></v-spacer>
                        <v-btn flat color="primary" @click="menu = false">Cancel</v-btn>
                        <v-btn flat color="primary" @click="$refs.dateInput.save(date)">OK</v-btn>
                    </v-date-picker>
                </v-menu>
            </v-flex> -->

      <v-flex
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['REFERRAL PURPOSE NOTES']"
          label="Purpose of referral"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['REFERRAL INFO TO BE ADDED']"
          label="Additional referral info"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/index'

export default {
  props: ['patientProfile'],
  data() {
    return {
      personalInfo: this.patientProfile,
      rules: {
        ...rules,
      },
    }
  },
  methods: {
    clickPrimaryCareToggle() {
      switch (this.personalInfo['REFERRALS PRIMARY CARE PHYSICIAN']) {
        case 'Yes':
          this.personalInfo[
            'REFERRAL PRIMARY CARE'
          ] = `${this.personalInfo['PRIMARY FIRST NAME']} ${this.personalInfo['PRIMARY LAST NAME']}`
          break
        default:
          this.personalInfo['REFERRAL PRIMARY CARE'] = ''
          break
      }

      return true
    },
  },
}
</script>

<style scoped>
</style>
