<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-expansion-panels multiple>
          <v-expansion-panel focusable>
            <v-expansion-panel-header> Tobacco use </v-expansion-panel-header>
            <v-expansion-panel-content :value="personalInfo['TOBACCO']">
              <div
                id="v-step-7"
                slot="header"
                class="title black--text text-sm-left"
              >
                Tobacco use <v-icon
                  v-show="personalInfo['TOBACCO']"
                  color="primary"
                  class="my-0 mx-2"
                >
                  check
                </v-icon>
              </div>
              <radio-list
                :state="personalInfo"
                :list="TABACCOLIST"
                @change="checked('TOBACCO', TABACCOLIST)"
              ></radio-list>
              <v-text-field
                v-show="personalInfo['TOBACCO YES']"
                v-model="personalInfo['NUMBER OF CIGS']"
                label="Number of cigarettes per day"
                :rules="[rules.required]"
                type="text"
              >
              </v-text-field>
              <v-select
                v-show="personalInfo['TOBACCO YES']"
                v-model="personalInfo['SMOKING CESSATION STATUS DROPDOWN']"
                :items="SMOKING_SENSATION_LIST"
                label="Patient Smoking cessation status?"
                single-line
                autocomplete
                @click.native="personalInfo['SMOKING CESSATION STATUS'] = 'Yes'"
              ></v-select>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>Recreational drugs use</v-expansion-panel-header>
            <v-expansion-panel-content
              :value="personalInfo['Recreational Drug Use']"
            >
              <v-card-text class="pt-0">
                <radio-list
                  :state="personalInfo"
                  :list="DRUGSLIST"
                  @change="checked('Recreational Drug Use', DRUGSLIST)"
                ></radio-list>
                <v-text-field
                  v-show="personalInfo['Recreational Drug Use']"
                  v-model="personalInfo['Recreational Drug Use Notes']"
                  label="Recreational drug use notes"
                  :rules="[rules.required]"
                  type="text"
                >
                </v-text-field>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Alcohol use</v-expansion-panel-header>
            <v-expansion-panel-content :value="personalInfo['ALCOHOL USE']">
              <v-card-text class="pt-0">
                <radio-list
                  :state="personalInfo"
                  :list="ALCOHOLLIST"
                  @change="checked('ALCOHOL USE', ALCOHOLLIST)"
                ></radio-list>

                <v-select
                  v-show="personalInfo['ALCOHOL USE YES']"
                  v-model="personalInfo['Alcohol use Dropdown']"
                  :items="ALCOHOL_FREQUENCY_LIST"
                  label="Alcohol use frequency"
                  single-line
                  autocomplete
                ></v-select>
              </v-card-text>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-expansion-panel>
            <v-expansion-panel-header>Exercise Regimen</v-expansion-panel-header>
            <v-expansion-panel-content
              :value="personalInfo['Exercise Regimen']"
            >
              <radio-list
                :state="personalInfo"
                :list="EXERCISEREGIMENLIST"
                @change="checked('Exercise Regimen', EXERCISEREGIMENLIST)"
              ></radio-list>
              <v-flex
                v-show="personalInfo['Exercise Regimen yes']"
                xs12
                md12
              >
                <v-text-field
                  v-model="personalInfo['MINUTES PER WEEK']"
                  label="How many minutes per week?"
                  :rules="[rules.required]"
                  type="text"
                >
                </v-text-field>
              </v-flex>
              <v-flex
                v-show="personalInfo['Exercise Regimen']"
                xs12
                md12
              >
                <v-text-field
                  v-model="personalInfo['EXERCISE REGIMEN NOTES']"
                  label="Exercise notes"
                  :rules="[rules.required]"
                  type="text"
                >
                </v-text-field>
              </v-flex>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-content :value="personalInfo['ALCOHOL USE']">
              <div
                slot="header"
                class="title black--text text-sm-left"
              >
                Alcohol use <v-icon
                  v-show="personalInfo['ALCOHOL USE']"
                  color="primary"
                  class="my-0 mx-2"
                >
                  check
                </v-icon>
              </div>
              <v-card>
                <v-card-text class="pt-0">
                  <radio-list
                    :state="personalInfo"
                    :list="ALCOHOLLIST"
                    @change="checked('ALCOHOL USE', ALCOHOLLIST)"
                  ></radio-list>

                  <v-select
                    v-show="personalInfo['ALCOHOL USE YES']"
                    v-model="personalInfo['Alcohol use Dropdown']"
                    :items="ALCOHOL_FREQUENCY_LIST"
                    label="Alcohol use frequency"
                    single-line
                    autocomplete
                  ></v-select>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>

            <v-expansion-panel-content :value="personalInfo['Exercise Regimen']">
              <div
                slot="header"
                class="title black--text text-sm-left"
              >
                Exercise Regimen <v-icon
                  v-show="personalInfo['Exercise Regimen']"
                  color="primary"
                  class="my-0 mx-2"
                >
                  check
                </v-icon>
              </div>
              <v-card>
                <v-card-text class="pt-0">
                  <radio-list
                    :state="personalInfo"
                    :list="EXERCISEREGIMENLIST"
                    @change="checked('Exercise Regimen', EXERCISEREGIMENLIST)"
                  ></radio-list>

                  <v-flex
                    v-show="personalInfo['Exercise Regimen yes']"
                    xs12
                    md12
                  >
                    <v-text-field
                      v-model="personalInfo['MINUTES PER WEEK']"
                      label="How many minutes per week?"
                      :rules="[rules.required]"
                      type="text"
                    >
                    </v-text-field>
                  </v-flex>

                  <v-flex
                    v-show="personalInfo['Exercise Regimen']"
                    xs12
                    md12
                  >
                    <v-text-field
                      v-model="personalInfo['EXERCISE REGIMEN NOTES']"
                      label="Exercise notes"
                      :rules="[rules.required]"
                      type="text"
                    >
                    </v-text-field>
                  </v-flex>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-flex>

      <!-- <v-flex xs12 md12>
        <v-combobox
          :items="LIFESTYLE_LIST"
          v-model="personalInfo['LIFESTYLE RECCOMENDATIONS DROPDOWN']"
          label="Lifestyle recomendations"
          multiple
        ></v-combobox> -->
      <!-- </v-flex> -->

      <v-flex
        xs12
        md12
      >
        <v-combobox
          v-model="personalInfo['LIFESTYLE RECCOMENDATIONS DROPDOWN']"
          :items="LIFESTYLE_LIST"
          label="Lifestyle recomendations"
          multiple
        ></v-combobox>
      </v-flex>

      <v-flex
        xs12
        md12
      >
        <v-text-field
          v-model="personalInfo['LIFESTYLE RECCOMENDATIONS']"
          label="Lifestyle recomendations notes"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/index'
import Radiolist from '@/components/common/Radiolist/Radiolist.vue'

const TABACCOLIST = [
  {
    name: 'TOBACCO YES',
    label: 'Yes',
    value: 'Yes',
  },
  {
    name: 'TOBACCO NO',
    label: 'No',
    value: 'Yes',
  },
]

const DRUGSLIST = [
  {
    name: 'Recreational Drug Use yes',
    label: 'Yes',
    value: 'Yes',
  },
  {
    name: 'Recreational Drug Use no',
    label: 'No',
    value: 'Yes',
  },
]

const ALCOHOLLIST = [
  {
    name: 'ALCOHOL USE YES',
    label: 'Yes',
    value: 'Yes',
  },
  {
    name: 'ALCOHOL USE NO',
    label: 'No',
    value: 'Yes',
  },
]

const CAFFINELIST = [
  {
    name: 'CAFFINE USE YES',
    label: 'Yes',
    value: 'Yes',
  },
  {
    name: 'CAFFINE USE NO',
    label: 'No',
    value: 'Yes',
  },
]

const EXERCISEREGIMENLIST = [
  {
    name: 'Exercise Regimen yes',
    label: 'Yes',
    value: 'Yes',
  },
  {
    name: 'Exercise Regimen no',
    label: 'No',
    value: 'Yes',
  },
]

const SMOKING_SENSATION_LIST = [
  'NA',
  'OFFERED AND ACCEPTED',
  'OFFERED BUT NOT ACCEPTED',
]

const ALCOHOL_FREQUENCY_LIST = [
  '1-3 DRINKS PER WEEK',
  '4-7 DRINKS PER WEEK',
  '8 DRINKS + PER WEEK',
  'NONE',
  'OCCASIONAL',
]

const LIFESTYLE_LIST = [
  'ADVISED HEALTHY MEAL OPTIONS',
  'CONSIDER SMOKING CESSATION',
  'CONTINUE HEALTHY LIFESTYLE',
  'HEALTHY DIET',
  'INCREASE ACTIVITY',
  'REDUCE ALCOHOL USE',
  'SODIUM RESTRICTED DIET',
]

export default {
  components: {
    'radio-list': Radiolist,
  },
  props: ['patientProfile'],
  data() {
    return {
      personalInfo: this.patientProfile,
      TABACCOLIST,
      DRUGSLIST,
      ALCOHOLLIST,
      CAFFINELIST,
      EXERCISEREGIMENLIST,
      SMOKING_SENSATION_LIST,
      ALCOHOL_FREQUENCY_LIST,
      LIFESTYLE_LIST,
      rules: {
        ...rules,
      },
    }
  },
  watch: {
    personalInfo: {
      // setting and resetting values based on state
      handler(state) {
        // exercise
        if (this.personalInfo['Exercise Regimen no']) {
          this.personalInfo['EXERCISE REGIMEN NOTES'] = 'None'
          this.personalInfo['MINUTES PER WEEK'] = ''
        } else {
          this.personalInfo['EXERCISE REGIMEN NOTES'] = ''
        }

        // recreational drugs
        if (this.personalInfo['Recreational Drug Use no']) {
          this.personalInfo['Recreational Drug Use Notes'] = 'None'
        } else {
          this.personalInfo['Recreational Drug Use Notes'] = ''
        }

        // tabacco
        if (this.personalInfo['TOBACCO NO']) {
          this.personalInfo['NUMBER OF CIGS'] = ''
          this.personalInfo['SMOKING CESSATION STATUS DROPDOWN'] = null
        }

        // alcohol
        if (this.personalInfo['ALCOHOL USE NO']) {
          this.personalInfo['Alcohol use Dropdown'] = null
        }
      },
      deep: true,
    },
  },
  methods: {
    checked(key, list) {
      this.personalInfo[key] = null
      const isChecked = list.findIndex(ref => this.personalInfo[ref.name]) != -1
      if (isChecked) {
        this.personalInfo[key] = 'Yes'
      }
      this.$forceUpdate()
    },
    autoCheckvalue(value, ref) {
      value.length > 0
        ? (this.personalInfo[ref] = 'Yes')
        : (this.personalInfo[ref] = '')
    },

    // clickTobaccoToggle(checkboxValue){
    //     switch (checkboxValue){
    //         case 'YES':
    //             this.personalInfo['TOBACCO'] = 'Yes';
    //             this.personalInfo['TOBACCO YES'] = 'Yes';
    //             this.personalInfo['TOBACCO NO'] = '';
    //             this.personalInfo['SMOKING CESSATION STATUS DROPDOWN'] = null;
    //         break;
    //         case 'NO':
    //             this.personalInfo['TOBACCO'] = 'Yes';
    //             this.personalInfo['TOBACCO NO'] = 'Yes';
    //             this.personalInfo['TOBACCO YES'] = '';
    //             this.personalInfo['NUMBER OF CIGS'] = '';
    //             this.personalInfo['SMOKING CESSATION STATUS DROPDOWN'] = null;
    //         break;
    //         default:
    //             this.personalInfo['TOBACCO'] = '';
    //             this.personalInfo['TOBACCO YES'] = '';
    //             this.personalInfo['TOBACCO NO'] = '';
    //             this.personalInfo['NUMBER OF CIGS'] = '';
    //             this.personalInfo['SMOKING CESSATION STATUS DROPDOWN'] = null;
    //         break;
    //     }

    //     return true;
    // },
    // clickRecDrugsToggle(checkboxValue){
    //     switch (checkboxValue){
    //         case 'YES':
    //             this.personalInfo['Recreational Drug Use'] = 'Yes';
    //             this.personalInfo['Recreational Drug Use yes'] = 'Yes';
    //             this.personalInfo['Recreational Drug Use no'] = '';
    //             this.personalInfo['Recreational Drug Use Notes'] = '';
    //         break;
    //         case 'NO':
    //             this.personalInfo['Recreational Drug Use no'] = 'Yes';
    //             this.personalInfo['Recreational Drug Use'] = '';
    //             this.personalInfo['Recreational Drug Use yes'] = '';
    //             this.personalInfo['Recreational Drug Use Notes'] = '';
    //         break;
    //         default:
    //             this.personalInfo['Recreational Drug Use'] = '';
    //             this.personalInfo['Recreational Drug Use yes'] = '';
    //             this.personalInfo['Recreational Drug Use no'] = '';
    //             this.personalInfo['Recreational Drug Use Notes'] = '';
    //         break;
    //     }

    //     return true;
    // },

    // clickAlcoholToggle(checkboxValue){
    //     switch (checkboxValue){
    //         case 'YES':
    //             this.personalInfo['ALCOHOL USE'] = 'Yes';
    //             this.personalInfo['ALCOHOL USE YES'] = 'Yes';
    //             this.personalInfo['ALCOHOL USE NO'] = '';
    //             this.personalInfo['Alcohol use Dropdown'] = null;
    //         break;
    //         case 'NO':
    //             this.personalInfo['ALCOHOL USE'] = 'Yes';
    //             this.personalInfo['ALCOHOL USE NO'] = 'Yes';
    //             this.personalInfo['ALCOHOL USE YES'] = '';
    //             this.personalInfo['Alcohol use Dropdown'] = null;
    //         break;
    //         default:
    //             this.personalInfo['ALCOHOL USE'] = '';
    //             this.personalInfo['ALCOHOL USE YES'] = '';
    //             this.personalInfo['ALCOHOL USE NO'] = '';
    //             this.personalInfo['Alcohol use Dropdown'] = null;
    //         break;
    //     }

    //     return true;
    // }
  },
}
</script>

<style scoped>
</style>
