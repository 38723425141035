<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <div slot="content">
      <doctor-search :refresh-num="refresh"></doctor-search>
      <v-btn
        id="v-step-6"
        color="primary"
        primary
        class="my-3"
        @click.native="openDrOverlay"
      >
        Select a Doctor
      </v-btn>
    </div>
    <v-layout
      row
      wrap
    >
      <v-flex xs6>
        <v-text-field
          v-model="personalInfo['PRIMARY FIRST NAME']"
          label="Primary provider first name"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>
      <v-flex xs6>
        <v-text-field
          v-model="personalInfo['PRIMARY LAST NAME']"
          label="Primary provider last name"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex xs12>
        <v-text-field
          v-model="personalInfo['PROVIDER DESIGNATION']"
          label="Designation"
          type="text"
        >
        </v-text-field>
      </v-flex>
      <v-flex xs6>
        <v-text-field
          v-model="personalInfo['PRIMARY PHONE #']"
          label="Phone"
          mask="phone"
          :rules="[rules.required]"
          type="phone"
        >
        </v-text-field>
      </v-flex>
      <v-flex xs6>
        <v-text-field
          v-model="personalInfo['PRIMARY FAX #']"
          label="Fax"
          mask="phone"
          :rules="[rules.required]"
          type="phone"
        >
        </v-text-field>
      </v-flex>

      <v-flex xs12>
        <v-text-field
          v-model="personalInfo['PRIMARY EMAIL ADDRESS']"
          label="Email"
          type="email"
        >
        </v-text-field>
      </v-flex>

      <v-flex xs12>
        <v-checkbox
          v-model="personalInfo['HEALTHCARE PROVIDERS WITH WHO TO COMMUNICATE']"
          color="primary"
          value="Yes"
          label="Is it ok to contact primary care provider?"
        />
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/index'

// import DoctorSearch from '@components/Forms/common/ui/Form/DoctorSearch/DoctorSearch'
import DoctorSearch from '@/components/common/ui/Form/DoctorSearch/DoctorSearch.vue'

export default {
  components: {
    'doctor-search': DoctorSearch,
  },
  props: ['patientProfile'],
  data() {
    return {
      personalInfo: this.patientProfile,
      rules: {
        ...rules,
      },
      DoctorData: {},
      refresh: true,
    }
  },
  computed: {
    isDoctorDataAvailable() {
      return Object.keys(this.DoctorData).length
    },
  },
  created() {
    // RULES
    this.personalInfo['PROVIDER DESIGNATION'] = 'Family MD'
    this.personalInfo['HEALTHCARE PROVIDERS WITH WHO TO COMMUNICATE'] = 'Yes'
  },
  methods: {
    openDrOverlay() {
      window.searchDoctor.open(doctor => {
        this.personalInfo['PRIMARY FIRST NAME'] = doctor.DoctorFirstName
        this.personalInfo['PRIMARY LAST NAME'] = doctor.DoctorLastName
        this.personalInfo['PRIMARY FAX #'] = doctor.DoctorFax
        this.personalInfo['PRIMARY PHONE #'] = doctor.DoctorPhone
        this.personalInfo['PRIMARY EMAIL ADDRESS'] = doctor.DoctorEmail
      })
    },
  },
}
</script>

<style scoped>
</style>
