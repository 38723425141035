<template>
  <v-container
    grid-list-md
    text-xs-left
  >
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-expansion-panels multiple>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Meter training
            </v-expansion-panel-header>
            <v-expansion-panel-content
              :value="personalInfo['SELF MONITORING METER TRAINING AWARE CHECKBOX']"
            >
              <!-- <div slot="header" class="title black--text text-sm-left">
                <v-icon
                  color="primary"
                  class="my-0 mx-2"
                  v-show="personalInfo['SELF MONITORING METER TRAINING AWARE CHECKBOX']"
                >check</v-icon>
              </div> -->
              <v-card>
                <v-card-text class="pt-0">
                  <check-list
                    :state="personalInfo"
                    :checklist="SELFMONITORINGMETERTRAININGAWARECHECKBOX"
                    @change="checked('SELF MONITORING METER TRAINING AWARE CHECKBOX', SELFMONITORINGMETERTRAININGAWARECHECKBOX)"
                  ></check-list>
                </v-card-text>
              </v-card>
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Testing frequency
            </v-expansion-panel-header>
            <v-expansion-panel-content :value="personalInfo['BLOOD SUGAR TESTING FREQUENCY']">
              <!-- <div slot="header" class="title black--text text-sm-left">
                <v-icon
                  color="primary"
                  class="my-0 mx-2"
                  v-show="personalInfo['BLOOD SUGAR TESTING FREQUENCY']"
                >check</v-icon>
              </div> -->
              <!-- <v-card> -->
              <!-- <v-card-text class="pt-0"> -->
              <check-list
                :state="personalInfo"
                :checklist="BLOODSUGARTESTINGFREQUENCY"
                @change="checked('BLOOD SUGAR TESTING FREQUENCY', BLOODSUGARTESTINGFREQUENCY)"
              ></check-list>
              <!-- </v-card-text> -->
              <!-- </v-card> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Recording of results
            </v-expansion-panel-header>
            <v-expansion-panel-content :value="personalInfo['RECORDING OF RESULTS']">
              <!-- <div slot="header" class="title black--text text-sm-left">
                <v-icon
                  color="primary"
                  class="my-0 mx-2"
                  v-show="personalInfo['RECORDING OF RESULTS']"
                >check</v-icon>
              </div> -->
              <!-- <v-card> -->
              <!-- <v-card-text class="pt-0"> -->
              <check-list
                :state="personalInfo"
                :checklist="RECORDINGOFRESULTS"
                @change="checked('RECORDING OF RESULTS', RECORDINGOFRESULTS)"
              ></check-list>
              <!-- </v-card-text> -->
              <!-- </v-card> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Patterns
            </v-expansion-panel-header>
            <v-expansion-panel-content :value="personalInfo['IDENTIFICATION OF PATTERNS']">
              <!-- <div slot="header" class="title black--text text-sm-left">
                <v-icon
                  color="primary"
                  class="my-0 mx-2"
                  v-show="personalInfo['IDENTIFICATION OF PATTERNS']"
                >check</v-icon>
              </div> -->
              <!-- <v-card> -->
              <!-- <v-card-text class="pt-0"> -->
              <check-list
                :state="personalInfo"
                :checklist="IDENTIFICATIONOFPATTERNS"
                @change="checked('IDENTIFICATION OF PATTERNS', IDENTIFICATIONOFPATTERNS)"
              ></check-list>
              <!-- </v-card-text> -->
              <!-- </v-card> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Hypoglycemia
            </v-expansion-panel-header>
            <v-expansion-panel-content :value="personalInfo['HYPOGLYCEMIA']">
              <!-- <div slot="header" class="title black--text text-sm-left">
                <v-icon
                  color="primary"
                  class="my-0 mx-2"
                  v-show="personalInfo['HYPOGLYCEMIA']"
                >check</v-icon>
              </div> -->
              <!-- <v-card> -->
              <!-- <v-card-text class="pt-0"> -->
              <check-list
                :state="personalInfo"
                :checklist="HYPOGLYCEMIA"
                @change="checked('HYPOGLYCEMIA', HYPOGLYCEMIA)"
              ></check-list>
              <!-- </v-card-text> -->
              <!-- </v-card> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Supply handling
            </v-expansion-panel-header>
            <v-expansion-panel-content :value="personalInfo['PROPER MED HANDLING']">
              <!-- <div slot="header" class="title black--text text-sm-left">
                <v-icon
                  color="primary"
                  class="my-0 mx-2"
                  v-show="personalInfo['PROPER MED HANDLING']"
                >check</v-icon>
              </div> -->
              <!-- <v-card> -->
              <!-- <v-card-text class="pt-0"> -->
              <check-list
                :state="personalInfo"
                :checklist="PROPERMEDHANDLING"
                @change="checked('PROPER MED HANDLING', PROPERMEDHANDLING)"
              ></check-list>
              <!-- </v-card-text> -->
              <!-- </v-card> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Disposal of supplies
            </v-expansion-panel-header>
            <v-expansion-panel-content :value="personalInfo['DISPOSAL']">
              <!-- <div slot="header" class="title black--text text-sm-left">
                <v-icon color="primary" class="my-0 mx-2" v-show="personalInfo['DISPOSAL']">check</v-icon>
              </div> -->
              <!-- <v-card> -->
              <!-- <v-card-text class="pt-0"> -->
              <check-list
                :state="personalInfo"
                :checklist="DISPOSAL"
                @change="checked('DISPOSAL', DISPOSAL)"
              ></check-list>
              <!-- </v-card-text> -->
              <!-- </v-card> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Sick days management
            </v-expansion-panel-header>
            <v-expansion-panel-content :value="personalInfo['SICK DAYS']">
              <!-- <div slot="header" class="title black--text text-sm-left">
                <v-icon color="primary" class="my-0 mx-2" v-show="personalInfo['SICK DAYS']">check</v-icon>
              </div> -->
              <!-- <v-card> -->
              <!-- <v-card-text class="pt-0"> -->
              <check-list
                :state="personalInfo"
                :checklist="SICKDAYS"
                @change="checked('SICK DAYS', SICKDAYS)"
              ></check-list>
              <!-- </v-card-text> -->
              <!-- </v-card> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Blood glucose target
            </v-expansion-panel-header>
            <v-expansion-panel-content :value="personalInfo['INDIVIDUALIZED BG TARGETS']">
              <!-- <div slot="header" class="title black--text text-sm-left">
                <v-icon
                  color="primary"
                  class="my-0 mx-2"
                  v-show="personalInfo['INDIVIDUALIZED BG TARGETS']"
                >check</v-icon>
              </div> -->
              <!-- <v-card> -->
              <!-- <v-card-text class="pt-0"> -->
              <v-layout
                row
                wrap
              >
                <v-flex
                  xs6
                  md3
                  class="mt-3"
                >
                  <v-checkbox
                    v-model="personalInfo['FPG']"
                    color="primary"
                    value="Yes"
                    label="FPG < 7 mmol/ or"
                    @click="personalInfo['INDIVIDUALIZED BG TARGETS'] = 'Yes'"
                  />
                </v-flex>

                <v-flex
                  xs6
                  md2
                >
                  <v-text-field
                    v-model="personalInfo['INDIVIDUALZIED FPG']"
                    label="FPG"
                    :rules="[rules.required]"
                    @input="personalInfo['INDIVIDUALIZED BG TARGETS'] = 'Yes'"
                  ></v-text-field>
                </v-flex>

                <v-flex
                  xs6
                  md3
                  offset-md2
                  class="mt-3"
                >
                  <v-checkbox
                    v-model="personalInfo['PPG']"
                    color="primary"
                    value="Yes"
                    label="PPG < 10 mmol/ or"
                    @click="personalInfo['INDIVIDUALIZED BG TARGETS'] = 'Yes'"
                  />
                </v-flex>

                <v-flex
                  xs6
                  md2
                >
                  <v-text-field
                    v-model="personalInfo['INDIVIDUALZIED PPG']"
                    label="PPG"
                    :rules="[rules.required]"
                    @input="personalInfo['INDIVIDUALIZED BG TARGETS'] = 'Yes'"
                  ></v-text-field>
                </v-flex>
              </v-layout>
              <!-- </v-card-text> -->
              <!-- </v-card> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
          <v-expansion-panel>
            <v-expansion-panel-header>
              Other notes
            </v-expansion-panel-header>
            <v-expansion-panel-content :value="personalInfo['SELF MONITORING OTHER']">
              <!-- <div slot="header" class="title black--text text-sm-left">
                <v-icon
                  color="primary"
                  class="my-0 mx-2"
                  v-show="personalInfo['SELF MONITORING OTHER']"
                >check</v-icon>
              </div> -->
              <!-- <v-card> -->
              <!-- <v-card-text class="pt-0"> -->
              <v-text-field
                v-model="personalInfo['SELF MONITORING OTHER NOTE']"
                label="Other notes"
                :rules="[rules.required]"
                @input="isCheckedText('SELF MONITORING OTHER','SELF MONITORING OTHER NOTE')"
              ></v-text-field>
              <!-- </v-card-text> -->
              <!-- </v-card> -->
            </v-expansion-panel-content>
          </v-expansion-panel>
        </v-expansion-panels>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { rules } from '@/util/index'
import Checklist from '@/components/common/Checklist/Checklist'

const SELFMONITORINGMETERTRAININGAWARECHECKBOX = [
  // {
  //     name: "SELF MONITORING METER TRAINING AWARE CHECKBOX",
  //     label: "SELF MONITORING METER TRAINING AWARE",
  //     value: "Yes"

  // },
  {
    name: 'SELF MONITORING METER TRAINING AWARE',
    label: 'Appropriate',
    value: 'Yes',
  },
  {
    name: 'SELF MONITORING METER TRAINING REVIEWED',
    label: 'Training reviewed',
    value: 'Yes',
  },
]

const BLOODSUGARTESTINGFREQUENCY = [
  // {
  //     name: "BLOOD SUGAR TESTING FREQUENCY",
  //     label: "Blood sugar testing frequency",
  //     value: "Yes"

  // },
  {
    name: 'SELF MONITORING FREQ 2-3',
    label: '2-3 times/week',
    value: 'Yes',
  },
  {
    name: 'SELF MONITORING FREQUECNY DAILY',
    label: 'Daily',
    value: 'Yes',
  },
  {
    name: 'SELF MONITORING FREQ MULTIPLE',
    label: '+2 times/day ',
    value: 'Yes',
  },
  {
    name: 'SELF MONITORING FREQ PHYSICIAN',
    label: 'As direct by the physician',
    value: 'Yes',
  },
]

const RECORDINGOFRESULTS = [
  // {
  //     name: "RECORDING OF RESULTS",
  //     label: "Recording of results",
  //     value: "Yes"

  // },
  {
    name: 'SELF MONITORING LOGBOOK',
    label: 'Logbook',
    value: 'Yes',
  },
  {
    name: 'SELF MONITORING GLUCOSE MACHINE',
    label: 'Glucose machine',
    value: 'Yes',
  },
]

const IDENTIFICATIONOFPATTERNS = [
  // {
  //     name: "IDENTIFICATION OF PATTERNS",
  //     label: "Identification of patterns",
  //     value: "Yes"

  // },
  {
    name: 'SELF MONITORING ELEVATED AM',
    label: 'Elevated AM',
    value: 'Yes',
  },

  {
    name: 'SELF MONITORING ELEVATED PM',
    label: 'Elevated PM',
    value: 'Yes',
  },
  {
    name: 'SELF MONITORING LVLS CONTROOLED',
    label: 'Levels controlled',
    value: 'Yes',
  },
]

const HYPOGLYCEMIA = [
  // {
  //     name: "HYPOGLYCEMIA",
  //     label: "Hypoglycemia",
  //     value: "Yes"

  // },
  {
    name: 'SELF MONITORING HYPO COUNCEL',
    label: 'Counselled patient',
    value: 'Yes',
  },
  {
    name: 'SELF MONITORING HYPO DEXTROSE TABLETS',
    label: 'Has dextrose tablets',
    value: 'Yes',
  },
]

const PROPERMEDHANDLING = [
  // {
  //     name: "PROPER MED HANDLING",
  //     label: "Proper med handling",
  //     value: "Yes"

  // },
  {
    name: 'SELF MONITORING SUPPLIES REVIEWED',
    label: 'Supplies reviewed',
    value: 'Yes',
  },
]

const DISPOSAL = [
  // {
  //     name: "DISPOSAL",
  //     label: "Disposal",
  //     value: "Yes"

  // },
  {
    name: 'DISPOSAL REVIEWED',
    label: 'Reviewed with patient',
    value: 'Yes',
  },
  {
    name: 'SELF MONITORING TRAINING ADVISED',
    label: 'Advised to return to pharmacy',
    value: 'Yes',
  },
  {
    name: 'SELF MONITORING TRAINING NA',
    label: 'NA',
    value: 'Yes',
  },
]

const SICKDAYS = [
  // {
  //     name: "SICK DAYS",
  //     label: "Sick days",
  //     value: "Yes"

  // },
  {
    name: 'SICK DAYS REVIEWED',
    label: 'Review with patient',
    value: 'Yes',
  },
]

//    const SELFMONITORINGOTHER = [
//         {
//             name: "SELF MONITORING OTHER",
//             label: "Self monitoring other",
//             value: "Yes"

//         }
//     ];

export default {
  components: {
    'check-list': Checklist,
  },
  props: ['patientProfile'],
  data() {
    return {
      personalInfo: this.patientProfile,
      SELFMONITORINGMETERTRAININGAWARECHECKBOX,
      BLOODSUGARTESTINGFREQUENCY,
      RECORDINGOFRESULTS,
      IDENTIFICATIONOFPATTERNS,
      HYPOGLYCEMIA,
      PROPERMEDHANDLING,
      DISPOSAL,
      SICKDAYS,

      // SELFMONITORINGOTHER,
      rules: {
        ...rules,
      },
    }
  },
  methods: {
    checked(key, list) {
      this.personalInfo[key] = null
      const isChecked = list.findIndex(ref => this.personalInfo[ref.name]) != -1

      if (isChecked) {
        this.personalInfo[key] = 'Yes'
      }

      this.$forceUpdate()
    },
    isCheckedText(checkboxKey, textKey) {
      this.personalInfo[checkboxKey] = this.personalInfo[textKey].length
        ? 'Yes'
        : null
    },
  },
}
</script>

<style scoped>
.expansion-panel__container:first-child {
  border-top: 1px solid #efefef !important;
}
</style>
