<template>
    <v-container grid-list-md text-xs-left>
        <v-layout row wrap>
            <v-flex v-for="(checkInstance, index) in checklistCollection " :key="index" md4 xs12>
                <v-checkbox @change="$emit('change', checkInstance.value, checkInstance)" color="primary" :true-value="checkInstance.value" false-value="" :label="checkInstance.label" :name="checkInstance.name" v-model="vm[checkInstance.name]" :hide-details="!checkInstance.hint" :persistent-hint="!!checkInstance.hint" :hint="checkInstance.hint"></v-checkbox>
            </v-flex>
        </v-layout>
    </v-container>
</template>

<script>
    export default {
        props: [
            'checklist',
            'state'
        ],
        data(){
            return {
                checklistCollection: this.checklist,
                vm: this.state
            }
        },
        created(){
            // auto check
            this.checklistCollection.map( (inst) => {
                if(inst.checked){
                    this.vm[inst.name] = inst.value;
                }
            });
            // update
            this.$forceUpdate();
        }
    }
</script>

<style scoped>

</style>