var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"footer-props":{'items-per-page-options': [10, 20, 30, 40, 50]},"disable-items-per-page":"","headers":_vm.headers,"items":_vm.medicationsList,"items-per-page":20,"item-key":"RxNumber","show-select":"","event":"","sort-by":"RxDate","sort-desc":_vm.sortDesc},on:{"click:row":_vm.rowClick},scopedSlots:_vm._u([_vm._l((_vm.headers.filter(function (header) { return header.hasOwnProperty('formatter'); }
    )),function(header){return {key:("item." + (header.value)),fn:function(ref){
    var header = ref.header;
    var value = ref.value;
return [_vm._v(" "+_vm._s(header.formatter(value))+" ")]}}}),{key:"item.onHold",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{staticClass:"v-chip-light-bg",class:((_vm.statusList[item.onHold]) + "--text"),attrs:{"hidden":!item.onHold,"small":"","color":_vm.statusList[item.onHold] || ''}},[_vm._v(" "+_vm._s(item.onHold)+" ")])]}},{key:"item.Status",fn:function(ref){
    var item = ref.item;
return [_c('v-chip',{staticClass:"tw-bg-red-500",attrs:{"color":item.Status === 'Complete'
        ? 'success'
        :item.Status === 'I' ? 'error'
          :''}},[_vm._v(" "+_vm._s(item.Status === null ? 'N/A' : item.Status)+" ")])]}}],null,true),model:{value:(_vm.selectedMedication),callback:function ($$v) {_vm.selectedMedication=$$v},expression:"selectedMedication"}})}
var staticRenderFns = []

export { render, staticRenderFns }