<template>
  <v-container
    grid-list-xs
    text-xs-left
    class="px-5 py-3 mx-0 my-3 elevation-6"
  >
    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        class="radio-list"
      >
        <radio-list
          :state="medicationInfo"
          :list="CHECKLIST_COLLECTION"
        ></radio-list>
      </v-flex>
    </v-layout>

    <v-layout
      row
      wrap
      justify-center
      align-center
    >
      <v-flex
        xs12
        md6
      >
        <v-text-field
          v-model="medicationInfo[`MED`]"
          label="Medication name"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md3
      >
        <v-text-field
          v-model="medicationInfo[`MED STR`]"
          label="Medication strength"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex
        xs12
        md3
      >
        <v-text-field
          v-model="medicationInfo[`FORM`]"
          label="Medication form"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>
    </v-layout>

    <v-layout
      row
      wrap
    >
      <v-flex
        xs12
        md8
      >
        <v-text-field
          v-model="medicationInfo[`SIG`]"
          label="Directions"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>
      <v-flex
        xs12
        md4
      >
        <v-combobox
          v-model="medicationInfo[`INDICATION`]"
          :items="indicationList"
          label="Indication"
          chips
        ></v-combobox>
      </v-flex>
    </v-layout>

    <v-layout
      row
      wrap
    >
      <v-flex
        xs2
        offset-xs1
      >
        <v-text-field
          id="v-step-10"
          v-model="medicationInfo[`MED AM QTY`]"
          label="Morning #"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex xs2>
        <v-text-field
          v-model="medicationInfo[`MED LUNCH QTY`]"
          label="Lunch #"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex xs2>
        <v-text-field
          v-model="medicationInfo[`MED DINNER QTY`]"
          label="Dinner #"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex xs2>
        <v-text-field
          v-model="medicationInfo[`MED BEDTIME QTY`]"
          label="Bedtime #"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex xs2>
        <v-text-field
          v-model="medicationInfo[`MED OTHER QTY`]"
          label="Other #"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>

      <v-flex xs1></v-flex>
    </v-layout>
    <v-layout
      row
      wrap
    >
      <v-flex xs4>
        <label id="v-step-11">Patient is adherent</label>
      </v-flex>
      <v-flex xs8>
        <radio-list
          :state="medicationInfo"
          :list="ADHERENCE_LIST"
          color="primary"
        ></radio-list>
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
    >
      <v-flex xs6>
        <v-combobox
          v-model="medicationInfo[`PATIENT COMMENT`]"
          :items="patientComments"
          label="Patient comments"
          multiple
          chips
        ></v-combobox>
      </v-flex>
      <v-flex xs6>
        <v-combobox
          v-model="medicationInfo[`MED PHARMACIST NOTES`]"
          :items="pharmasistNotes"
          label="Pharmacist Notes"
          chips
          multiple
        ></v-combobox>
      </v-flex>
    </v-layout>
    <v-layout
      row
      wrap
    >
      <v-flex xs12>
        <v-text-field
          v-model="medicationInfo[`COMMENTS FOR MEDSCHECK RECORD`]"
          multi-line
          rows="3"
          label="Pharmacist comments"
          :rules="[rules.required]"
          type="text"
        >
        </v-text-field>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
// import { rules } from '../../util/validations.js';
import { rules } from '@/util/index'
import Checklist from '@/components/common/Checklist/Checklist.vue'
import Radiolist from '@/components/common/Radiolist/Radiolist.vue'

const INDICATION_COLLECTION = [
  'A. FIB',
  'ACID REFLUX',
  'ALLERGIES',
  'ANGINA',
  'ANTIBIOTIC',
  'ANTIFUNGAL',
  'ANTIPSYCHOTIC',
  'ANXIETY',
  'ASTHMA',
  'BACK PAIN',
  'BIRTH CONTROL',
  'BLOOD PRESSURE',
  'BLOOD THINNER',
  'CARDIAC PROTECTION',
  'CHOLESTEROL',
  'CONSTIPATION',
  'COUGH',
  'DEPRESSION',
  'DIABETES',
  'DIARRHEA',
  'DIURETIC',
  'DRY MOUTH',
  'EDEMA',
  'EYES',
  'GERD',
  'GOUT',
  'HEADACHE',
  'HORMONE THERAPY',
  'INSOMNIA',
  'IRON',
  'KIDNEY STONES',
  'LAXATIVE',
  'MIGRAINE',
  'NASAL CONGESTION',
  'NEUROPATHY',
  'OSTEOPOROSIS',
  'PAIN',
  "PARKINSON'S",
  'PROSTATE',
  'RASH',
  'RESPIRATORY',
  'RHEUMATOID ARTHRITIS',
  'SLEEP',
  'SORE THROAT',
  'STOOL SOFTENER',
  'THYROID',
  'URINARY INCONTINENCE',
  'VITAMIN',
]

const PATIENT_COMMENTS_COLLECTION = [
  'DOING WELL',
  'NEW RX',
  'EXPERIENCING SIDE EFFECTS',
]

const PHARMASIST_NOTES_COLLECTION = [
  'TAKING CORRECTLY AND DOING WELL',
  'NEW RX/COUNSELLED PATIENT',
  'NOT TAKING AS PRESCRIBED',
  'REFER TO DOCTOR',
]

export default {
  components: {
    'radio-list': Radiolist,
  },
  props: [
    // 'patientProfile',
    'medication',

    // 'id'
  ],
  data() {
    const CHECKLIST_COLLECTION = [
      {
        name: 'RX?',
        label: 'RX',
        value: 'Yes',
      },
      {
        name: 'OTC?',
        label: 'OTC',
        value: 'Yes',
      },
      {
        name: 'NHP?',
        label: 'NHP',
        value: 'Yes',
      },
    ]

    const ADHERENCE_LIST = [
      {
        name: 'ADHERENCE YES',
        label: 'Yes',
        value: 'Yes',
      },
      {
        name: 'ADHERENCE NO',
        label: 'No',
        value: 'Yes',
      },
    ]

    return {
      // key: this.id,
      medicationInfo: this.medication,

      // personalInfo: this.patientProfile,
      CHECKLIST_COLLECTION,
      ADHERENCE_LIST,
      indicationList: INDICATION_COLLECTION,
      patientComments: PATIENT_COMMENTS_COLLECTION,
      pharmasistNotes: PHARMASIST_NOTES_COLLECTION,
      rules: {
        ...rules,
      },
    }
  },

  created() {
    // Object.keys(this.medicationInfo).map( (key) => {
    //     if(key.indexOf('INDICATION') !== -1 && !!this.medicationInfo[key]){
    //         // make it an array
    //         this.medicationInfo[key] = [this.medicationInfo[key].toUpperCase()];
    //     }else{
    //         this.medicationInfo[key] = this.medicationInfo[key];
    //     }
    // });
  },

  // methods: {
  //     toggle: (checkboxChoice) => {
  //         // if(checkboxChoice === 'YES' && !!this.personalInfo['ADHERENCE NO 1'] ){
  //         //     this.personalInfo['ADHERENCE NO 1'] = false;
  //         // }

  //         // if(checkboxChoice === 'NO' && !!this.personalInfo['ADHERENCE YES 1']){
  //         //     this.personalInfo['ADHERENCE YES 1'] = false;
  //         // }
  //     }
  // }
}
</script>

<style scoped>
.wrapper {
  border-top: 1px solid rgba(0, 0, 0, 0.12);
  border-radius: 8px;
  /* padding: 10px; */
  box-shadow: 0 14px 28px rgba(0, 0, 0, 0.25), 0 10px 10px rgba(0, 0, 0, 0.22);
}
</style>
